import { useQuery, useQueryClient } from 'react-query';
import AxiosClient from './AxiosClient';
import constants from './constants';
import { useAuth } from './authTokenContext';

const { api } = constants;
const { basePath, options } = api;

export const Search = async (queryKey, context, params) => {
    const {
        config = { 
            headers: { 
                Authorization: `Bearer ${context.token}`, 
                'Content-Type': 'application/json',
                'Cache-Control': 'no-store'
            },
        },
        entity,
        includes = [],
        filter,
        filter_eq,
        filter_in,
        filter_not_in,
        expand_multi,
        expands,
        sort,
        filter_or,
        added_joins,
        searchValue = {},
        tenantId,
        paging,
    } = context;
    try {
        const pathname = `${basePath}${entity}/search`;

        const payload = {
            ...options,
            filter,
            filter_eq,
            filter_in,
            filter_not_in,
            filter_or,
            pagingInfo: paging,
            sort,
            added_joins,
            expand_multi,
            expands,
            includes,
        };
        const results = await AxiosClient.post(pathname, payload, config);
        const { data } = results;

        return {
            data: data.data?.data || [],
            schema_def: data.schema_def || { schema_definition: { fields: [] } },
            count: data.data?.count,
        };
    } catch (err) {
        console.error(err.message);
        throw new Error(`Trouble searching ${entity}.`);
    }
};

const useSearch = (context) => {
    const auth = useAuth();
    return useQuery(
        [
            `search-${context.entity}`,
            {
                ...auth,
                ...context,
            },
        ],
        (args, item) => Search(args, item, context)
    );
};

export const useSearchClear = (context) => {
    const auth = useAuth();
    const queryClient = useQueryClient()

    //queryClient.invalidateQueries(context.entity)
    return {
        search: useQuery(
            [
                `search-${context.entity}`,
                {
                    ...auth,
                    ...context,
                },
            ],
            (args, item) => Search(args, item, context)
        ),
        clear: async () => {
            await queryClient.invalidateQueries(`search-${context.entity}`)

        },
    };
};
export default useSearch;
