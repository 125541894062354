import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../../components/controlls/PButton";

export const ProficiencyRatingsAdd = ({save,proficiency_group_id})  =>{
    const [data, setData] = useState({});
    const [show, setShow] = useState(false);

    const onSave = async () =>{
        await save({...data,proficiency_group_id});
        setData({});
        setShow(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});
    }
    return <>
        <button className={"btn btn--primary"} onClick={()=>setShow(true)}>Add</button>
        <Dialog header="Add Account" style={{ width: '50vw' }}  visible={show} onHide={() => setShow(false)}>
            <div className="form--group">
                <label htmlFor="">Display</label>
                <input name={"name"} onChange={onChange} value={data["name"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Order</label>
                <input name={"order"} onChange={onChange} value={data["order"]} type="number" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Value</label>
                <input name={"numeric_value"} onChange={onChange} value={data["numeric_value"]} type="number" className="form--input"/>
            </div>
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </>;
}