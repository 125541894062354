import axios from 'axios'

const {
    REACT_APP_API_BASEURL: baseURL
} = process.env;
/*
  this will give us a single place for setting the baseUrl
  and any headers we may need throughout the application.
*/
const instance = axios.create({
    baseURL,
    timeout: 0,
    headers: {
        'Content-Type': 'application/json'
    }
});

const get = async (pathname, config) => {
    try {
        const results = await instance.get(pathname, Object.assign({},config,{ timeout: 0 }));
        const { data } = results;
        return data
    } catch (err) {
        const errorMessage = err.message ? err.message : 'Trouble with api. [GET]'
        console.error(errorMessage);
        throw new Error(errorMessage)
    }
};

const post = async (pathname, payload, config) => {
    try {
        const results = await instance.post(
            pathname,
            payload,
            config
        );
        const { data } = results;

        return data
    } catch (err) {
        const errorMessage = err.message ? err.message : 'Trouble with api. [POST]'
        console.error(errorMessage);
        throw new Error(errorMessage)
    }
};

const put = async (pathname, payload, config) => {
    try {
        const results = await instance.put(
            pathname,
            payload,
            config
        );
        const { data } = results;

        return data
    } catch (err) {
        const errorMessage = err.message ? err.message : 'Trouble with api. [PUT]'
        console.error(errorMessage);
        throw new Error(errorMessage)
    }
};
const deleteItem = async (pathname, payload, config) => {
    try {
        const results = await instance.delete(
            pathname,
            config
        );
        const { data } = results;

        return data
    } catch (err) {
        const errorMessage = err.message ? err.message : 'Trouble with api. [DELETE]'
        console.error(errorMessage);
        throw new Error(errorMessage)
    }
};
export default instance

export { get, post, put,deleteItem }
