import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const getData = ({objPath}) =>{
    const getData = async () =>{
        const {config} = authorizationService();
        let {data:{data:users}} = await axios.get(`${settings.url}/api/${objPath}`, config());
        return users;
    }
    return {
        get: async () =>{
            return await getData();
        }
    }
}