import React, { useEffect, useState } from "react";

import { SelectAccount } from "../../Selectors/SelectAccount";
import { SelectOpportunity } from "../../Selectors/SelectOpportunity";
import { SelectProperty } from "../../Selectors/SelectProperty";
import * as moment from "moment";
export const GeneralJobForm = ({ onChange, data }) => {
  return (
    <>
      <div className="form">
        <div className="form--group">
          <label
            className="block text-sm font-medium leading-6 text-gray-900"
            htmlFor=""
          >
            Name
          </label>
          <input
            name={"name"}
            onChange={onChange}
            value={data["name"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label
            className="block text-sm font-medium leading-6 text-gray-900"
            htmlFor=""
          >
            Status
          </label>
          <select
            name={"status"}
            onChange={onChange}
            value={+data["status"]}
            className="form--input"
          >
            <option>--Select One</option>
            <option value={1}>Open</option>
            <option value={0}>Closed</option>
          </select>
        </div>
        <div className="form--group">
          <label
            className="block text-sm font-medium leading-6 text-gray-900"
            htmlFor=""
          >
            Description
          </label>
          <textarea
            name={"job_description"}
            onChange={onChange}
            value={data["job_description"]}
            className="form--input"
          />
        </div>

        <div className="form--group">
          <SelectOpportunity
            onChange={onChange}
            selectedOpportunity={data["opportunity"]}
          />
        </div>
        <div className="form--group">
          <SelectAccount
            onChange={onChange}
            selectedAccount={data["account"]}
          />
        </div>
        <div className="form--group">
          <SelectProperty
            onChange={onChange}
            selectedItem={data["property"]}
            accountFilter={data["account"]?.id}
          />
        </div>

        <div className="form--group">
          <label
            className="block text-sm font-medium leading-6 text-gray-900"
            htmlFor=""
          >
            Job Start
          </label>
          <input
            type="date"
            name={"job_start"}
            onChange={onChange}
            value={moment(data["job_start"]).format("YYYY-MM-DD")}
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label
            className="block text-sm font-medium leading-6 text-gray-900"
            htmlFor=""
          >
            Job End
          </label>
          <input
            type="date"
            name={"job_end"}
            onChange={onChange}
            value={moment(data["job_end"]).format("YYYY-MM-DD")}
            className="form--input"
          />
        </div>
      </div>
    </>
  );
};
