import {useProficiencyGroupService} from "../../services/proficiencies/useProficiencyGroupService";
import React, {useEffect, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import * as PropTypes from "prop-types";
import {useProficiencyRatingService} from "../../services/proficiencies/useProficiencyRatingService";
import {useProficiencyCategoryService} from "../../services/proficiencies/useProficiencyCategoryService";
import {useVendorProficiencyValueService} from "../../services/proficiencies/useVendorProficiencyValueService";

function ProficiencyCategories({group,vendorId}) {
    const { data:categories} = useProficiencyCategoryService({params:{proficiency_group_id:group.id}});
    const {data:ratings} = useProficiencyRatingService({params:{proficiency_group_id:group.id}});
    const {data:values,createList} =useVendorProficiencyValueService({params:{proficiency_group_id:group.id, vendor_id:vendorId}});
    const [ratingsData, setRatingsData] = useState([]);
    useEffect(()=>{
        setRatingsData(values);
    },[values]);
    const getValueItem = (category_id) =>{
         let rating = ratingsData.find(i=>i.proficiency_category_id === category_id);
         if(rating)
             return rating.proficiency_rating_id;
         return null;
    }
    const updateRating = (category_id, rating_id)=>{
        let tmpRatings = [...ratingsData];
        let rating = tmpRatings.find(i=>i.proficiency_category_id === category_id);
        if(rating){
            rating.proficiency_rating_id = rating_id;
            tmpRatings[tmpRatings.indexOf(rating)] = rating;
        }else{
            tmpRatings.push({vendor_id:vendorId, proficiency_group_id:group.id,proficiency_category_id:category_id, proficiency_rating_id: rating_id });
        }
        setRatingsData(tmpRatings);
    }
    const saveData = async () =>{
        await createList(ratingsData.map(({id,vendor_id, proficiency_group_id,proficiency_category_id,proficiency_rating_id})=>{
            return {id,vendor_id, proficiency_group_id,proficiency_category_id,proficiency_rating_id};
        }));
    }
    return <>
    <div>
        <button className={"btn btn--primary"} onClick={async ()=>{
            await saveData()
        }}>Save</button>
        {categories.map((category, ind)=>{
            return <div key={"category-item-" + category.id}>
                <label htmlFor="">{category.name}</label>
                <div>
                    <select value={getValueItem(category.id)} name="" id="" className={"form--input"} onChange={({target:{value}})=>{
                        updateRating(category.id, value);
                    }}>
                        <option >--Select One</option>
                        {ratings.map((rating, item)=>{
                            return <option key={`option-item-${category.id}-${rating.id}`} value={rating.id}>{rating.name}</option>
                        })}
                    </select>
                </div>
            </div>
        })}
    </div>

    </>;
}

function ProficiencyRating({group}) {

    return null;
}

export const ProficiencyGrid = ({vendorId}) =>{
    const {save:saveGroup, groupsByVendor} = useProficiencyGroupService({});
    const [groups, setGroups] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(()=>{
        groupsByVendor(vendorId).then((res)=>{
            setGroups(res)
        });
    },[])
    return <div>
        <h2>Services </h2>
        {!groups || groups.length === 0 && <>
            <h3>No Groups Defined</h3>
        </>}
        {groups && <>
            <TabView className={"full-tab-view"}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {groups.map((item, index)=>{
                    return <TabPanel key={`tab-item-${index}`} header={item.name}>
                        <div className={"display-row"}>
                            <div className={"display-column panel"}>
                                <ProficiencyCategories group={item} vendorId={vendorId}></ProficiencyCategories>
                            </div>
                        </div>
                    </TabPanel>
                })}

            </TabView>
        </>}
    </div>
}