import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { PButton } from "../../controlls/PButton";
import { GeneralOpportunityForm } from "./GeneralOpportunityForm";

export const AddOpportunity = ({
  save,
  setShowModal,
  showModal = false,
  currentRow,
  updated,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(currentRow);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  useEffect(() => {
    setData(currentRow);
  }, [currentRow]);

  const onSave = async () => {
    await save(data);
    setData({});
    setShowModal(false);
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      {showModal && (
        <div className={"absolute top-0 left-0 w-full h-100 bg-white pb-2"}>
          <div className={"flex"}>
            <h2>Add Opportunity</h2>
          </div>
          <div>
            <GeneralOpportunityForm data={data || {}} onChange={onChange} />
          </div>
          <div className={"pb-3"}>
            <PButton
              text={"cancel"}
              click={async () => {
                setShowModal(false);
              }}
            />
            <PButton
              text={"save"}
              click={async () => {
                await onSave();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
