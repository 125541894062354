import { usePropertyTypesService } from "../../../services/usePropertyTypeService";
import React, { useEffect, useState } from "react";
import { TypeAheadSearch } from "../../controlls/TypeAhead/TypeAheadSearch";
import { SelectAccount } from "../../Selectors/SelectAccount";

export const GeneralOpportunityForm = ({ onChange, data }) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="form">
        <div className="form--group">
          <label htmlFor="">Title</label>
          <input
            name={"title"}
            onChange={onChange}
            value={data["title"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <SelectAccount
            selectedAccount={data["account"]}
            onChange={onChange}
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Stage</label>
          <input
            name={"stage"}
            onChange={onChange}
            value={data["stage"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Close Date</label>
          <input
            name={"close_date"}
            onChange={onChange}
            value={data["close_date"]}
            type="date"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Op Amount</label>
          <input
            name={"op_amount"}
            onChange={onChange}
            value={data["op_amount"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Scope Summary</label>
          <textarea
            name={"scope_summary"}
            onChange={onChange}
            value={data["scope_summary"]}
            className="form--input"
          />
        </div>

        <div className="form--group">
          <label htmlFor="">Lead Source</label>
          <input
            name={"lead_source"}
            onChange={onChange}
            value={data["lead_source"]}
            type="text"
            className="form--input"
          />
        </div>

        <div className="form--group">
          <label htmlFor="">Next Step</label>
          <input
            name={"next_step"}
            onChange={onChange}
            value={data["next_step"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Description</label>
          <textarea
            name={"description"}
            onChange={onChange}
            value={data["description"]}
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">QB Link</label>
          <input
            name={"qb_link"}
            onChange={onChange}
            value={data["qb_link"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Quotient LInk</label>
          <input
            name={"quotient_link"}
            onChange={onChange}
            value={data["quotient_link"]}
            type="text"
            className="form--input"
          />
        </div>
      </div>
    </>
  );
};
