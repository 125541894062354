import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const useEquipmentTypesService = () =>{
    const {config} = authorizationService();
    return {
        get:async ()=>{
            let {data:{equipment_types}} = await axios.get(`${settings.url}/api/equipment_types`,config());
            return equipment_types;
        },
        getOne:async (id)=>{
            let {data:{equipment_type}} = await axios.get(`${settings.url}/api/equipment_types/${id}`,config());
            return equipment_type;
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/equipment_types/${id}`,config());
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/equipment_types/${data.id}`,data,config()) :
                await axios.post(`${settings.url}/api/equipment_types`,data,config());
        }}
}