import moment from "moment/moment";
import { JobTextEditor } from "./JobTextEditor";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import * as PropTypes from "prop-types";
const user = {
  name: "Whitney Francis",
  email: "whitney@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80",
};
export function MessageDisplay({ comment }) {
  return (
    <>
      <li className={""} key={comment.id}>
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={user.imageUrl}
              alt=""
            />
          </div>
          <div>
            <div className="text-sm">
              <a href="#" className="font-medium text-gray-900">
                {comment.name}
              </a>
            </div>
            <div className="mt-1 text-sm text-gray-700">
              <p dangerouslySetInnerHTML={{ __html: comment.note }}></p>
            </div>
            <div className="mt-2 space-x-2 text-sm">
              <span className="font-medium text-gray-500">
                {moment(comment.created_at).format("hh:mm A")}
              </span>{" "}
              <span className="font-medium text-gray-500">&middot;</span>{" "}
              <button type="button" className="font-medium text-gray-900">
                Reply
              </button>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}

export function MessageInput({ message, setMessage, onSave }) {
  return (
    <>
      <div className="bg-gray-50 px-4 py-3 sm:px-6">
        <div className="flex space-x-3">
          <div className="flex-shrink-0">
            <img
              className="h-10 w-10 rounded-full"
              src={user.imageUrl}
              alt=""
            />
          </div>
          <div className="min-w-0 flex-1">
            <form action="#">
              <div>
                <label htmlFor="comment" className="sr-only">
                  About
                </label>
                <div className="block bg-white p-2 w-full rounded-md border-0 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:py-1.5 sm:text-sm sm:leading-6">
                  <JobTextEditor
                    message={message}
                    setMessage={setMessage}
                  ></JobTextEditor>
                </div>
              </div>
              <div className="mt-3 flex items-center justify-between">
                <a
                  href="#"
                  className="group inline-flex items-start space-x-2 text-sm text-gray-500 hover:text-gray-900"
                >
                  <QuestionMarkCircleIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  <span>Some HTML is okay.</span>
                </a>
                <button
                  type="submit"
                  className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={onSave}
                >
                  Comment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

MessageInput.propTypes = {
  onSave: PropTypes.func,
  message: PropTypes.string,
  setMessage: PropTypes.func,
};
