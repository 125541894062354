import React, { useEffect, useState } from "react";
import { AddressView } from "../../components/controlls/Address/AddressView";
import { useDataAttributeService } from "../../services/useDataAttributeService";
import { EditRow } from "../../components/controlls/DetailTemplates/Headers";
import { useAccountService } from "../../services/useAccountService";
import { GeneralAccountForm } from "../../components/management/account/GeneralAccountForm";

export const AccountDetailView = ({ data, setData, onSave }) => {
  const [editMode, setEditMode] = useState(false);

  const { data: attributes } = useDataAttributeService({
    params: { attribute_type: "customer" },
  });

  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="md:flex md:items-center md:justify-between py-3">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Detailed Account Information
            </h3>

            <EditRow
              showCancel={true}
              editMode={editMode}
              onEdit={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={async () => {
                await onSave();
                setEditMode(false);
              }}
            />
          </div>
        </div>

        {editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <GeneralAccountForm data={data} onChange={onChange} />
            </div>
          </>
        )}
        {!editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Account Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["name"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Website</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["website"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["email"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["phone"]}
                  </dd>
                </div>
                {attributes.map((attr, index) => {
                  return (
                    <>
                      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                        <dt className="text-sm font-medium text-gray-500">
                          {attr.attribute_display}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                          {(data.attribute_data || {})[attr.attribute_name] ||
                            ""}
                        </dd>
                      </div>
                    </>
                  );
                })}
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
