import {Button} from "primereact/button";
import React from "react";

export const formatDate = (value) => {
    return value.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
}

export const formatCurrency = (value) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const basicParams = {
    className:"p-datatable-customers",
    paginatorTemplate:"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    rowsPerPageOptions:[10,25,50],
    dataKey:"id",
    filterDisplay:"row",
    responsiveLayout:"scroll",
    currentPageReportTemplate:"Showing {first} to {last} of {totalRecords} entries",
    lazy:true,
    paginator:true,
    rowHover:true
};