import {useBasicServices} from "./useBasicServices";
import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const useVendorService = ({id,params={},context=[]}) =>{
    let base = useBasicServices({service_type:'vendors', params:params,context:context});
    const {config} = authorizationService();
    return {...base,
        getAttributes:async ()=>{
            let {data} = await axios.get(`${settings.url}/api/vendors/${id}/attributes`,config())
            return data?.data || [];
        }};
}