import {useAuth} from './authTokenContext';
import {useQuery} from 'react-query';
import {ObjContainer} from '../object_container';

const useFieldUpdater = ({state,setState,onUpdate}) => {

    return {update:(field, value)=>{
        let temp = {...state};
        temp[field] = value;
        if(onUpdate)
        {onUpdate(temp);}
        setState(temp);
        return temp;
    }};
}; 

export default useFieldUpdater;
