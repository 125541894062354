import {Button, ButtonGroup, FormGroup, Input, Modal} from "reactstrap";
import classnames from "classnames";
import React, {useState} from "react";

export const AddEventModal = ({save, modalAdd, setModalAdd}) =>{
    const [radios, setRadios] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);
    const [eventDescription, setEventDescription] = useState(null);
    const addNewEvent = async () => {
        const {_id: id} = JSON.parse(localStorage.getItem("user"));
        //ToDo: Create Tasks
        //const {data} = await createTask(id, eventTitle, eventDescription, radios, startDate, endDate);
        const data = [];
        /*if (data.success) {
            const {task} = data;
            console.log(data);
            newEvents.push({
                title: eventTitle,
                start: startDate,
                end: endDate,
                className: radios,
                id: task._id,
                description: eventDescription,
                allDay: true
            });
            calendar.addEvent({
                title: eventTitle,
                start: startDate,
                end: endDate,
                className: radios,
                id: task._id,
                description: eventDescription,
                allDay: true
            });
        }*/

        setModalAdd(false);

        setRadios("bg-info");
        setEventTitle(undefined);
    };
    return <Modal
        isOpen={modalAdd}
        toggle={() => setModalAdd(false)}
        className="modal-dialog-centered modal-secondary"
    >
        <div className="modal-body">
            <form className="new-event--form">
                <FormGroup>
                    <label className="form-control-label">Event title</label>
                    <Input
                        className="form-control-alternative new-event--title"
                        placeholder="Event Title"
                        type="text"
                        onChange={(e) => setEventTitle(e.target.value)}
                    />
                </FormGroup>
                <FormGroup className="mb-0">
                    <label className="form-control-label d-block mb-3">
                        Status color
                    </label>
                    <ButtonGroup
                        className="btn-group-toggle btn-group-colors event-tag"
                        data-toggle="buttons"
                    >
                        <Button
                            className={classnames("bg-info", {
                                active: radios === "bg-info",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-info")}
                        />
                        <Button
                            className={classnames("bg-warning", {
                                active: radios === "bg-warning",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-warning")}
                        />
                        <Button
                            className={classnames("bg-danger", {
                                active: radios === "bg-danger",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-danger")}
                        />
                        <Button
                            className={classnames("bg-success", {
                                active: radios === "bg-success",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-success")}
                        />
                        <Button
                            className={classnames("bg-default", {
                                active: radios === "bg-default",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-default")}
                        />
                        <Button
                            className={classnames("bg-primary", {
                                active: radios === "bg-primary",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-primary")}
                        />
                    </ButtonGroup>
                </FormGroup>
                <FormGroup>
                    <label className="form-control-label">Description</label>
                    <Input
                        className="form-control-alternative edit-event--description textarea-autosize"
                        placeholder="Event Desctiption"
                        type="textarea"
                        defaultValue={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                    />
                    <i className="form-group--bar" />
                </FormGroup>
            </form>
        </div>
        <div className="modal-footer">
            <Button
                className="new-event--add"
                color="primary"
                type="button"
                onClick={addNewEvent}
            >
                Add event
            </Button>
            <Button
                className="ml-auto"
                color="link"
                type="button"
                onClick={() => setModalAdd(false)}
            >
                Close
            </Button>
        </div>
    </Modal>
}