import React, {useEffect, useState} from "react";
import {useLaborService} from "../../../services/useLaborService";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../controlls/PButton";
import {useContactService} from "../../../services/useContactService";

export const AddJobTask = ({setShowModal, showModal, save,currentRow, update}) =>{
    const {data:contactList} = useContactService();

    const onSave = async () =>{
        await save({...currentRow});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        update( {...currentRow,[name]:value});

    }
    return <div>
        <Dialog header="Add Supplier" style={{ width: '50vw' }}  visible={showModal} onHide={() => setShowModal(false)}>
            <div className="form--group">
                <label htmlFor="">Title</label>
                <input name={"title"} onChange={onChange} value={currentRow["title"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Due Date</label>
                <input name={"due_date"} onChange={onChange} value={currentRow["due_date"]} type="date" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Assigned To</label>
                <select name={"assigned_to_id"} onChange={onChange} value={currentRow["assigned_to_id"]}  className="form--input">
                    <option value="">--Select One</option>
                    {contactList.map((item, index)=>{
                        return <option key={"labor_type_" + index} value={item.id}> {item.first_name} {item.last_name}</option>
                    })}
                </select>
            </div>
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}