import React, { useEffect, useState } from "react";
import { usePropertyService } from "../../services/usePropertyService";
import { Dialog } from "primereact/dialog";
import { CategorySelection } from "../../common/CategorySelection";
import { PButton } from "../../components/controlls/PButton";
import { useVendorService } from "../../services/useVendorService";
import { useOpportunityPropertiesService } from "../../services/useOpportunityPropertiesService";
import { BasicApiMap } from "../../components/controlls/Maps/BasicApiMap";
export const SelectNearby = ({ property }) => {
  const [showModal, setShowModal] = useState(false);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [curProperty, setCurProperty] = useState({});
  const {
    get,
    data: vendors,
    searchByCategories,
  } = useVendorService({
    params: {},
    context: [],
  });
  //vendor_categories: categories ToDo: rebuild the find by categories
  useEffect(() => {
    if (property) {
      let shouldQuery = curProperty?.id !== property?.id;
      setCategories(property?.vendor_categories?.map((i) => i.id) || []);
      setLocations([property?.property, ...vendors]);
      setCurProperty(property);
      if (shouldQuery) {
        get().then((res) => {});
      }
    } else {
      setCurProperty({});
    }
  }, [property]);
  useEffect(() => {
    setLocations([property?.property, ...vendors]);
  }, [vendors]);
  if (!property || !property.property) return <>Select a property</>;
  return (
    <>
      <br />
      <button className={"btn btn--primary"} onClick={() => setShowModal(true)}>
        Nearby
      </button>
      <Dialog
        header="Select Vendor"
        style={{ width: "50vw" }}
        visible={showModal}
        onHide={() => setShowModal(false)}
      >
        <BasicApiMap locationItems={locations} />
      </Dialog>
    </>
  );
};
export const OpportunityVendorAdd = ({
  opportunityId,
  save,
  setShowModal,
  showModal = false,
  currentRow,
  updated,
}) => {
  const [data, setData] = useState(currentRow);
  const { data: vendors } = useVendorService({ params: {} });
  const { data: properties } = useOpportunityPropertiesService({
    params: { opportunity_id: opportunityId },
  });
  const [property, setProperty] = useState({});

  useEffect(() => {
    setData(currentRow);
  }, [currentRow]);

  const onSave = async () => {
    await save({
      ...data,
      opportunity_id: opportunityId,
      property_id: property.property_id,
    });
    setShowModal(false);
  };
  const onChange = ({ target: { name, value } }) => {
    if (name === "opportunity_property_id") {
      setProperty(properties.find((i) => i.id === +value));
    }

    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <Dialog
        header="Add Property"
        style={{ width: "50vw" }}
        visible={showModal}
        onHide={() => setShowModal(false)}
      >
        <div className="form--group">
          <label htmlFor="">Property</label>
          <select
            name={"opportunity_property_id"}
            onChange={onChange}
            value={data["opportunity_property_id"]}
            className="form--input"
          >
            <option value="">--Select One</option>
            {properties.map((item, index) => {
              return (
                <option key={"property_" + index} value={item.id}>
                  {" "}
                  {item.property.name}{" "}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form--group">
          <label htmlFor="">Vendor</label>
          <select
            name={"vendor_id"}
            onChange={onChange}
            value={data["vendor_id"]}
            className="form--input"
          >
            <option value="">--Select One</option>
            {vendors.map((item, index) => {
              return (
                <option key={"vendor_" + index} value={item.id}>
                  {" "}
                  {item.name}{" "}
                </option>
              );
            })}
          </select>
          <SelectNearby property={property}></SelectNearby>
        </div>
        <div className="form--group">
          <label htmlFor="">Cost</label>
          <input
            type="number"
            className={"form--input"}
            value={data["cost"]}
            name="cost"
            min="1"
            step="any"
            onChange={onChange}
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Description</label>
          <textarea
            className={"form--input"}
            name="description"
            value={data["description"]}
            onChange={onChange}
          ></textarea>
        </div>

        <div>
          <PButton
            text={"save"}
            click={async () => {
              await onSave();
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};
