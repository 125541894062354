import React, { useState } from "react";
import * as moment from "moment";
import { GeneralOpportunityForm } from "../../components/management/opportunity/GeneralOpportunityForm";
import { PButton } from "../../components/controlls/PButton";
import { useOpportunityService } from "../../services/useOpportunityService";
import { useOpportunityVendorService } from "../../services/useOpportunityVendorService";
import { useOpportunityPropertiesService } from "../../services/useOpportunityPropertiesService";
import { EditRow } from "../../components/controlls/DetailTemplates/Headers";
import { Link } from "react-router-dom";
export const OpportunityView = ({ data, setData, opportunityId }) => {
  const [editMode, setEditMode] = useState(false);
  const { save, getOne } = useOpportunityService({});
  const { data: vendors } = useOpportunityVendorService({
    params: { opportunity_id: opportunityId },
  });
  const { data: properties } = useOpportunityPropertiesService({
    params: { opportunity_id: opportunityId },
  });
  const onSave = async () => {
    await save({ ...data });
    await getRecord();
    setEditMode(false);
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  const getRecord = async () => {
    let res = await getOne(opportunityId);
    setData(res);
  };
  return (
    <div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="md:flex md:items-center md:justify-between py-3">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Detailed Opportunity Information
            </h3>

            <EditRow
              showCancel={true}
              editMode={editMode}
              onEdit={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={onSave}
            />
          </div>
        </div>
        {editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <GeneralOpportunityForm data={data} onChange={onChange} />
            </div>
          </>
        )}
        {!editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200 ">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Title</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["title"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Customer
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data.account?.name}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Stage</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["stage"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Close Date
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {moment(data["close_date"]).format("MM/DD/yyyy")}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Op Amount
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["op_amount"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Scope Summary
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["scope_summary"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Lead Source
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["lead_source"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Next Step
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["next_step"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["description"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Quotient Link
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["quotient_link"]}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Quickbooks Link
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["qb_link"]}
                  </dd>
                </div>
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
