import {VendorContactView} from "./VendorContactView";
import {GeneralContactForm} from "../../components/management/contact/GeneralContactForm";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";
import {PButton} from "../../components/controlls/PButton";
import {useAccountContactService} from "../../services/useAccountContactService";
import {useVendorContactService} from "../../services/useVendorContactService";

export const VendorContactDetails = () =>{
    let { vendorContactId } = useParams();
    const [data, setData] = useState({lat:0, lng:0});
    const [editMode, setEditMode] = useState(false);
    const {save, getOne} = useVendorContactService({});
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();

    useEffect(()=>{
        getOne(vendorContactId).then((res)=>{
            setData(res);
        })
    },[]);
    const onSave = async () =>{
        await save({...data});
        setEditMode(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});
    }
    return <div>

        <div style={{height:"100%"}}>
            <h1>Vendor Contact</h1>
            <TabView className={"full-tab-view"}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="General">
                    {!editMode && <>
                        <VendorContactView data={data}/>
                        <div>
                            <PButton text={"edit"} click={async ()=>{
                                setEditMode(true);
                            }}/>
                            <PButton text={"cancel"} click={async ()=>{
                                navigate("/vendor_contacts");
                            }}/>
                        </div>
                    </>
                    }
                    {editMode && <>
                        <GeneralContactForm data={data || {}} onChange={onChange} />
                        <div>
                            <PButton text={"save"} click={async ()=>{
                                await onSave();
                            }}/>
                            <PButton text={"cancel"} click={async ()=>{
                                setEditMode(false);
                            }}/>
                        </div>
                    </>
                    }
                </TabPanel>
            </TabView>
        </div>


    </div>
}