import axios from "axios";
import { settings } from "./settings";
import { useEffect, useState } from "react";
import { authorizationService } from "./authorizationService";

export const useBasicServices = ({
  service_type,
  id,
  params,
  context = [],
}) => {
  const [data, setData] = useState([]);
  const [singleItem, setSingleItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  useEffect(() => {
    get().then((res) => {});
  }, context);
  useEffect(() => {
    if (params.id) {
      getOne(params.id).then((res) => {
        setSingleItem(res);
      });
    }
  }, [params]);
  const paramString = (paramsL) => {
    let res = [];
    for (let param in paramsL) {
      if (Array.isArray(paramsL[param])) {
        res.push(`${param}=${paramsL[param].join(",")}`);
      } else {
        res.push(`${param}=${paramsL[param]}`);
      }
    }
    if (res.length === 0) return "";
    return "?" + res.join("&");
  };
  const get = async () => {
    setLoading(true);
    let {
      data: {
        data: rows,
        pagination: { page, pageSize, rowCount },
      },
    } = await axios.post(
      `${settings.url}/api/${service_type}/search`,
      params,
      config()
    );
    setLoading(false);
    setData(rows);
    setPagination({ page, pageSize, rowCount });
    return rows;
  };
  const getOne = async (id) => {
    let {
      data: { data: row },
    } = await axios.get(`${settings.url}/api/${service_type}/${id}`, config());
    return row;
  };
  const search = async (params) => {
    let {
      data: {
        data: rows,
        pagination: { page, pageSize, rowCount },
      },
    } = await axios.post(
      `${settings.url}/api/${service_type}/search`,
      params,
      config()
    );
    setPagination({ page, pageSize, rowCount });
    return rows;
  };
  const getByParams = async (params) => {
    let {
      data: { data: rows },
    } = await axios.get(
      `${settings.url}/api/${service_type}${paramString(params)}`,
      config()
    );
    return rows;
  };
  const { config } = authorizationService();
  return {
    hasMore: pagination.rowCount > pagination.page * pagination.pageSize,
    addNextPage: async () => {
      let {
        data: {
          data: rows,
          pagination: { page, pageSize, rowCount },
        },
      } = await axios.post(
        `${settings.url}/api/${service_type}/search`,
        { ...params, page: pagination.page + 1 },
        config()
      );
      setPagination({ page, pageSize, rowCount });
      setData([...data].concat(rows));
      return [...data].concat(rows);
    },
    pagination,
    loading,
    refresh: get,
    data: data,
    singleItem,
    get: get,
    getByParams,
    getOne: getOne,
    onChange: async ({
      rows,
      sortField,
      sortOrder,
      multiSortMeta,
      filters,
      page,
      globalFields,
    }) => {
      let data = await search({
        rows,
        sortField,
        sortOrder,
        multiSortMeta,
        filters,
        page,
        globalFields,
      });
      setData(data);
    },
    deleteData: async (id) => {
      await axios.delete(`${settings.url}/api/${service_type}/${id}`, config());
      await get();
    },
    createList: async (data) => {
      await axios.post(`${settings.url}/api/${service_type}`, data, config());
      await get();
    },
    save: async (data) => {
      data.id
        ? await axios.put(
            `${settings.url}/api/${service_type}/${data.id}`,
            { ...data },
            config()
          )
        : await axios.post(
            `${settings.url}/api/${service_type}`,
            { ...data },
            config()
          );
      await get();
    },
  };
};
