import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../components/controlls/PButton";
import {usePropertyService} from "../../services/usePropertyService";
import {CategorySelection} from "../../common/CategorySelection";
import {useVendorVendorCategoryService} from "../../services/useVendorVendorCategoryService";

export const OpportunityPropertyAdd = ({accountId,opportunityId,save,setShowModal, showModal=false,currentRow, updated}) =>{
    const [data, setData] = useState(currentRow);
    const {data:properties} = usePropertyService({params:{account_id: accountId}});
    const [selectedKeys, setSelectedKeys] = useState({});

    useEffect(()=>{
        let keys = {}
        console.log("current row", currentRow.vendor_categories);
        currentRow.vendor_categories?.forEach(i => {
            keys[i.id] = {checked:true};
        });
        setSelectedKeys(keys)
        setData(currentRow);
    },[currentRow]);

    const onSave = async () =>{
        let categories = Object.keys(selectedKeys).map((item)=>{
            return {opportunity_property_id: currentRow.id, vendor_category_id:item}
        })
        await save({...data, opportunity_id:opportunityId,vendor_categories:categories});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});

    }
    return <div>
        <Dialog header="Add Property" style={{ width: '50vw' }}  visible={showModal} onHide={() => setShowModal(false)}>
            <div className="form--group">
                <label htmlFor="">Property</label>
                <select name={"property_id"} onChange={onChange} value={data["property_id"]}  className="form--input">
                    <option value="">--Select One</option>
                    {properties.map((item, index)=>{
                        return <option key={"property_" + index} value={item.id}> {item.name} </option>
                    })}
                </select>
            </div>
            <div className="form--group">
                <label htmlFor="">Cost</label>
                <input type="number" className={"form--input"} value={data["cost"]} name="cost" min="1" step="any" onChange={onChange}/>
            </div>
            <div className="form--group">
                <label htmlFor="">Description</label>
                <textarea className={"form--input"} name="description" value={data["description"]} onChange={onChange}></textarea>
            </div>

            <div className="form--group">
                <label htmlFor="">Categories</label>
                <CategorySelection selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys}></CategorySelection>
            </div>


            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}