import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const usePlacesSearch = () =>{
    const {config} = authorizationService();
    return {
        search:async (searchObj) =>{
            let {data} = await axios.post(`${settings.url}/api/places_search`,searchObj,config())
            return data;
        }
    }
}