import { useBasicServices } from "./useBasicServices";
import axios from "axios";
import { settings } from "./settings";
import { authorizationService } from "./authorizationService";

export const useUserService = ({ params = {} }) => {
  const { config } = authorizationService();
  return {
    ...useBasicServices({ service_type: "users", params: params }),
    resendWelcome: async (id) => {
      await axios.post(`${settings.url}/api/users/resend_welcome`, config());
    },
  };
};
