import { useOpportunityService } from "../../services/useOpportunityService";
import { useListHelper } from "../../hooks/useListHelper";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Link } from "react-router-dom";
import { basicParams } from "../../common/listUtils";
import { PButton } from "../controlls/PButton";
import { AddOpportunity } from "../management/opportunity/AddOpportunity";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import { useAccountService } from "../../services/useAccountService";
import { AddAccount } from "../management/account/AddAccount";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  UserIcon,
  GlobeAltIcon,
  PhoneIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import InfiniteScroll from "react-infinite-scroll-component";

export const AccountList = () => {
  const {
    pagination,
    data: accounts,
    loading,
    deleteData,
    save,
    onChange,
    hasMore,
    addNextPage,
  } = useAccountService({});
  const { show, setShow, currentRow, onGlobalFilterChange } = useListHelper({
    title: "Customers",
    pagination,

    onChange,
    globalFields: ["name", "website", "email", "phone"],
    filters: {
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    },
    onDelete: async (row) => {
      await deleteData(row.id);
    },
  });

  return (
    <div className="">
      <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Accounts
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <button
              onClick={() => setShow(true)}
              type="button"
              className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create new account
            </button>
          </div>
        </div>
      </div>

      <div className="py-4 bg-white  flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-5 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
            placeholder="Search..."
            type="search"
            name="search"
            onChange={onGlobalFilterChange}
          />
        </form>
      </div>

      <div className={"data-action-row"}>
        <AddAccount
          save={save}
          setShowModal={setShow}
          showModal={show}
          currentRow={currentRow}
        >
          {" "}
        </AddAccount>
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200 overflow-hidden">
          <InfiniteScroll
            dataLength={accounts.length}
            next={addNextPage}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Showing All Results</b>
              </p>
            }
          >
            {accounts.map((account) => (
              <li key={account.id}>
                <a
                  href={`/accounts/${account.id}`}
                  className="block hover:bg-gray-50"
                >
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="flex-shrink-0">
                        {account.imageUrl && (
                          <img
                            className="h-12 w-12 rounded-full"
                            src={account.imageUrl}
                            alt=""
                          />
                        )}
                        {!account.imageUrl && (
                          <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        )}
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {account.name}
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <EnvelopeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="truncate">{account.email}</span>
                          </p>

                          <div className="block md:hidden">
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <PhoneIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <phone>{account.phone}</phone>
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <GlobeAltIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                {account.website}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <PhoneIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <phone>{account.phone}</phone>
                          </p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            <GlobeAltIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="truncate">{account.website}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <ChevronRightIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </InfiniteScroll>
        </ul>
      </div>
    </div>
  );
};
