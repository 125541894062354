import React, { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { usePropertyService } from "../../services/usePropertyService";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function SelectProperty({ onChange, selectedItem, accountFilter }) {
  const [query, setQuery] = useState("");
  const [selectItems, setSelectItems] = useState([]);
  const { get } = usePropertyService({
    params: {
      account_id: accountFilter,
      filters: { name: { matchMode: "contains", value: query } },
    },
  });
  useEffect(() => {
    get().then((res) => {
      setSelectItems(res);
    });
  }, [query, accountFilter]);
  return (
    <>
      <Combobox
        as="div"
        value={selectedItem}
        onChange={(item) => {
          onChange({ target: { value: item.id, name: "property_id" } });
          onChange({ target: { value: item, name: "property" } });
        }}
      >
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
          Property
        </Combobox.Label>
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border bg-white border-grey-600  py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(opportunity) => opportunity?.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {selectItems.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {selectItems.map((item) => (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex">
                        <span
                          className={classNames(
                            "truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {item.name}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
}
