import { Link, useParams } from "react-router-dom";
import { useJobLaborService } from "../../services/useJobLaborService";
import { useListHelper } from "../../hooks/useListHelper";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { basicParams } from "../../common/listUtils";
import { PButton } from "../controlls/PButton";
import { AddJobLabor } from "../management/job_labor/AddJobLabor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import { useOpportunityService } from "../../services/useOpportunityService";
import { AddOpportunity } from "../management/opportunity/AddOpportunity";
import {
  BriefcaseIcon,
  CalendarIcon,
  ChevronRightIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import moment from "moment/moment";

import InfiniteScroll from "react-infinite-scroll-component";

export const OpportunityList = () => {
  const {
    data: opportunities,
    deleteData,
    save,
    onChange,
    pagination,
    addNextPage,
    hasMore,
  } = useOpportunityService({});
  const { show, setShow, currentRow, onGlobalFilterChange } = useListHelper({
    title: "Opportunities",
    onChange,
    pagination,
    globalFields: ["global", "status"],
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    onDelete: async (row) => {
      await deleteData(row.id);
    },
  });

  return (
    <>
      <div className="">
        <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Opportunities
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                onClick={() => setShow(true)}
                type="button"
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create new opportunity
              </button>
            </div>
          </div>
        </div>

        <div className="py-4 bg-white  flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full w-full border-0 py-0 pl-5 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              onChange={onGlobalFilterChange}
            />
          </form>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            <InfiniteScroll
              dataLength={opportunities.length}
              next={addNextPage}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Showing All Results</b>
                </p>
              }
            >
              {opportunities.map((opportunity) => (
                <li key={opportunity.id}>
                  <a
                    href={`/opportunities/${opportunity.id}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="flex min-w-0 flex-1 items-center">
                        <div className="flex-shrink-0">
                          <BriefcaseIcon
                            className="mr-1.5 h-10 w-10 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {opportunity.title}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <GlobeAltIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                {opportunity.account?.name}
                              </span>
                            </p>
                            <div className="block md:hidden">
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <PhoneIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Starts: </span>
                                <phone>
                                  {moment(opportunity.start).format(
                                    "MM/DD/YYYY"
                                  )}
                                </phone>
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <GlobeAltIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Ends: </span>
                                <span className="truncate">
                                  {moment(opportunity.job_end).format(
                                    "MM/DD/YYYY"
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <CalendarIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Starts: </span>
                              <phone>
                                {moment(opportunity.job_start).format(
                                  "MM/DD/YYYY"
                                )}
                              </phone>
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <CalendarIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Ends: </span>
                              <span className="truncate">
                                {moment(opportunity.job_end).format(
                                  "MM/DD/YYYY"
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </InfiniteScroll>
          </ul>
        </div>
      </div>

      <div className="datatable-doc-demo">
        <div className={"data-action-row"}>
          <AddOpportunity
            save={save}
            setShowModal={setShow}
            showModal={show}
            currentRow={currentRow}
          >
            {" "}
          </AddOpportunity>
        </div>
        <div className="card"></div>
      </div>
    </>
  );
};
