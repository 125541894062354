import {Wrapper} from "@googlemaps/react-wrapper";
import React, {useEffect, useRef, useState} from "react";
import {Map} from "../../components/maps/Map";
import {Marker} from "../../components/maps/Marker";
import {useSupplierService} from "../../services/useSupplierService";
import {Popup} from "../../components/maps/Popup";
import {usePlacesSearch} from "../../services/usePlacesSearch";
import ReactDOMServer from "react-dom/server";
import {Card, Container, Row} from "reactstrap";

export const MapSearch = ({isMarkerShown=true}) =>{
    const ref = useRef(null);
    const [map, setMap] = useState();
    const [searchObj, setSearchObj] = useState({});
    const [isReset, setIsReset] = useState(true);
    const [clicks, setClicks] = useState([]);
    const [dataResults, setDataResults] = useState([]);
    const [source, setSource] = useState([]);

    const {search:searchPlaces} = usePlacesSearch();
    const [zoom, setZoom] = useState(3); // initial zoom
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });
    const {get} = useSupplierService();

    const onClick = (e) => {
        setClicks([...clicks, e.latLng] );
    };

    const onIdle = (m) => {
        if(isReset){
            setZoom(m.getZoom());
            setCenter(m.getCenter().toJSON());
            let bounds = new window.google.maps.LatLngBounds();
            clicks.forEach(({lat, lng})=>{
                bounds.extend(new window.google.maps.LatLng(lat, lng));
            })
            m.fitBounds(bounds);
            setIsReset(false);
            setMap(m);
        }

    };
    useEffect(()=>{
        get().then((res)=>{
            setSource(res);
            setDataResults(res);
            let points = res.map((item)=>{
                return {lat:item.latitude, lng:item.longitude,title:item.name,typeChar: 'S',info:item};
            })
            let centerCalc = points.reduce((calc, items)=>{
                calc.lat = calc.lat + items.lat;
                calc.lng = calc.lng + items.lng;
                return calc;
            },{lat:0,lng:0});
            setCenter({lat:centerCalc.lat / points.length,lng:centerCalc.lng / points.length});
            setClicks(points);
            setZoom(14);
        })
    },[]);
    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);
    const search = async () =>{
        let {results:{results}} = await searchPlaces(searchObj);

        let mappedPlaceResults = results.map(({name,vicinity, geometry})=>{
            let addressParts = vicinity.split(",");
            return {name:name, address1:addressParts[0], city:addressParts[1], state:'MD',latitude:geometry.location.lat, longitude:geometry.location.lng};
        });
        let mappedResults = mappedPlaceResults.map(({name,address1, city,state,zip, latitude,longitude})=>{

            return {title:name, lat:latitude, lng:longitude, typeChar:'S', info:{name,address1, city,state,zip, latitude,longitude}};
        });
        setClicks(mappedResults);
        setDataResults(mappedPlaceResults);
        let bounds = new window.google.maps.LatLngBounds();
        mappedResults.forEach(({lat, lng})=>{
            bounds.extend(new window.google.maps.LatLng(lat, lng));
        })
        map.fitBounds(bounds);
    }
    const onChangeHandler = ({target:{name, value}}) =>{
        setSearchObj({...searchObj,...{[name]:value}});
    }
    return <div >
        <h2>Supplier Locator</h2>
        <div className={"row"}>
            <div className={"form--group"}>
                <label htmlFor="">Source Location</label>
                <select name="" id="" className={"form--input"} onChange={({target})=>{
                    let item = source.find(i=>i.id === +target.value);
                    onChangeHandler({target: {name:"refPoint", value: {lat:item.latitude, lng:item.longitude} }});
                }} >
                    <option value="">--Choose Source</option>
                    {source.map((item, index)=>{
                        return <option key={"location_item_" + index} value={item.id}>{item.name}</option>
                    })}
                </select>
            </div>
            <div className={"form--group"}>
                <label htmlFor="">Distance</label>
                <input type="text" placeholder={"distance (miles)"} name={"distance"} className={"form--input"}  onChange={onChangeHandler}/>
            </div>
            <div className={"form--group"}>
                <label htmlFor="">Search </label>
                <input type="text" className={"form--input"} name={"text"} placeholder={"Search"} onChange={onChangeHandler}/>
            </div>
            <button  className={"btn btn--primary"} onClick={async ()=>{
                await search();
            }}>Search</button>
        </div>
        <div style={{ display: "flex", height: "600px" }}>
            <div className={"map-results"}>
                {dataResults.map((result,ind)=>{
                    return <><div key={"map-result-" + ind} className={"map-results--item"}>
                        <h3><button className={"link-button"} onClick={()=>{
                                map.setCenter(new window.google.maps.LatLng(result.latitude, result.longitude));
                                setZoom(16);
                        }}>{result.name}</button></h3>
                        <div>{result.address1}</div>
                        <div>{result.address2}</div>
                        <div>{result.city} {result.state}, {result.zip}</div>
                        <button className={"btn btn--primary"}>Add</button>
                    </div>

                    </>

                })}
            </div>
            <Container>
                <Row>
                    <div className="col">
                        <Card className={"border-0"}>
                            <Wrapper apiKey={"AIzaSyD-qq-lA7Cy4GXVOfa27QnxtMpVr2XoQeE"} >
                                <Map
                                    center={center}
                                    onIdle={onIdle}
                                    zoom={zoom}
                                    style={{ flexGrow: "1", height: "600px" }}
                                >
                                    {clicks.map((item, i) => (
                                        <Marker key={i} position={item} label={item.title} info={item.info} ></Marker>
                                    ))}

                                </Map>
                            </Wrapper>
                        </Card>
                    </div>
                </Row>
            </Container>

            </div>
    </div>
}