import { Link, useParams } from "react-router-dom";
import { useJobLaborService } from "../../services/useJobLaborService";
import { useListHelper } from "../../hooks/useListHelper";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { basicParams } from "../../common/listUtils";
import { PButton } from "../../components/controlls/PButton";
import { AddJobLabor } from "../../components/management/job_labor/AddJobLabor";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import { useOpportunityPropertiesService } from "../../services/useOpportunityPropertiesService";
import { OpportunityPropertyAdd } from "./OpportunityPropertyAdd";
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
  MapIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { AddProperty } from "../../components/management/properties/AddProperty";

export const OpportunityProperties = ({ accountId }) => {
  let { opportunityId } = useParams();
  const {
    data: properties,
    loading,
    deleteData,
    save,
    pagination,
    onChange,
  } = useOpportunityPropertiesService({
    params: { opportunity_id: opportunityId },
  });
  const {
    lazyParams,
    renderHeader,
    actionBodyTemplate,
    filters,
    show,
    setShow,
    currentRow,
    onGlobalFilterChange,
  } = useListHelper({
    title: "Contacts",
    pagination,
    onChange,
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      address1: { value: null, matchMode: FilterMatchMode.CONTAINS },
      city: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cost: { value: null, matchMode: FilterMatchMode.CONTAINS },
      description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    onDelete: async (row) => {
      await deleteData(row.id);
    },
  });
  const detailsLinkTemplate = (rowData) => {
    return (
      <Link to={`/property/${rowData.property_id}`}>
        {rowData.property.name}
      </Link>
    );
  };
  const dtParams = {
    ...basicParams,
    ...lazyParams,
    value: properties,
    header: renderHeader(),
    loading: loading,
    globalFilterFields: ["name", "address1", "city", "state", "zip", "status"],
    emptyMessage: "No contacts found.",
  };

  const columnExp = [
    {
      field: "property.name",
      header: "Name",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Name",
      style: { minWidth: "14rem" },
      body: detailsLinkTemplate,
    },
    {
      field: "property.address1",
      header: "Address",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search Address",
      style: { minWidth: "14rem" },
    },
    {
      field: "property.city",
      header: "City",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by City",
      filterField: "email",
      style: { minWidth: "14rem" },
    },
    {
      field: "cost",
      header: "Cost",
      sortable: true,
      filter: true,
      sortField: "state",
      filterField: "state",
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
    {
      field: "description",
      header: "Description",
      sortable: true,
      filter: true,
      sortField: "state",
      filterField: "state",
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
  ];
  return (
    <>
      <div className="">
        <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Properties
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                onClick={() => setShow(true)}
                type="button"
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create new property
              </button>
            </div>
          </div>
        </div>

        <div className="py-4 bg-white  flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full w-full border-0 py-0 pl-5 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              onChange={onGlobalFilterChange}
            />
          </form>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {properties.map((property) => (
              <li key={property.id}>
                <a
                  href={`/property/${property.id}`}
                  className="block hover:bg-gray-50"
                >
                  <div className="flex items-center px-3 py-4 sm:px-3">
                    <div className="flex min-w-0 flex-1 items-center flex-grow">
                      <div className="flex-shrink">
                        {property.imageUrl && (
                          <img
                            className="h-12 w-12 rounded-full"
                            src={property.imageUrl}
                            alt=""
                          />
                        )}
                        {!property.imageUrl && (
                          <span className="inline-block overflow-hidden rounded-full">
                            <MapPinIcon
                              className=" h-10 w-10 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {property.property.name}
                          </p>

                          <p className="block md:hidden mt-2 flex items-center text-sm text-gray-500">
                            <MapIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <p className="truncate">
                              {property.property.address1} <br />
                              {property.property.city},{" "}
                              {property.property.state} {property.property.zip}
                            </p>
                          </p>
                        </div>
                      </div>
                      <div className="hidden md:block min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <p className="truncate text-sm font-medium text-gray-500"></p>
                        <p className=" mt-2 flex items-center text-sm text-gray-500">
                          <MapIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <p className="truncate">
                            {property.property.address1} <br />
                            {property.property.city}, {property.property.state}{" "}
                            {property.property.zip}
                          </p>
                        </p>
                      </div>

                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="datatable-doc-demo">
        <div className={"data-action-row"}>
          <OpportunityPropertyAdd
            opportunityId={opportunityId}
            accountId={accountId}
            save={save}
            setShowModal={setShow}
            showModal={show}
            currentRow={currentRow}
          >
            {" "}
          </OpportunityPropertyAdd>
        </div>
      </div>
    </>
  );
};
