import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../controlls/PButton";
import {GeneralContactForm} from "../contact/GeneralContactForm";


export const AddPropertyContact = ({property_id,save,setShowModal, showModal=false,currentRow, updated}) =>{
    const [show, setShow] = useState(false);
    const [data, setData] = useState(currentRow || {contact:{}});

    useEffect(()=>{
        setShow(showModal)
    },[showModal])
    useEffect(()=>{
        console.log("data", currentRow);
        setData(currentRow);
    },[currentRow])
    const onSave = async () =>{
        console.log("data", data);
        await save({contact:{...data.contact},id:data.id, property_id:property_id});
        setData({contact:{}});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,contact:{...data.contact, [name]:value}});
    }
    return <div>
        <Dialog header="Add Property Contact" style={{ width: '50vw' }}  visible={show} onHide={() => setShowModal(false)}>
            <GeneralContactForm data={data.contact || {}} onChange={onChange} />
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}