import { createContext, useContext } from 'react';

const contextData  = {
    token: localStorage.getItem("login_data") ? JSON.parse(localStorage.getItem("login_data"))?.jwt : undefined
};
const Context = createContext(contextData);

const useAuthToken = () => {
    return useContext(Context);
}
export const useAuthOps = () =>{
    return {setToken:(token)=>{
        contextData.token = token;
    }}
}
export { useAuthToken as useAuth, useAuthToken }
export default Context;

