import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../../components/controlls/PButton";

export const  VendorCategoryAdd = ({save,item={},selectedNode={key:0} }) => {
    const [data, setData] = useState({});
    const [show, setShow] = useState(false);
    useEffect(()=>{
        setData({...item})
    },[item])
    const onSave = async () =>{
        if(data.id){
            await save({id:data.id,name:data.name});
        }else{
            await save({name:data.name,parent_vendor_category_id: selectedNode.key});
        }

        setData({});
        setShow(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});
    }
    return <>
        <div>
            <button className={"btn btn--primary"} onClick={()=>{
                setData({});
                setShow(true)
            }}>Add</button>
            <button className={"btn btn--primary"} onClick={()=>{
                setShow(true)
            }}>Edit</button>
            <Dialog header="Add Category" style={{ width: '50vw' }}  visible={show} onHide={() => setShow(false)}>
                <div className="form--group">
                    <label htmlFor="">Name</label>
                    <input name={"name"} onChange={onChange} value={data["name"]} type="text" className="form--input"/>
                </div>
                <div>
                    <PButton text={"save"} click={async ()=>{
                        await onSave();
                    }}/>
                </div>
            </Dialog>
        </div>

    </>;
}