import * as moment from "moment/moment";
import React, { useState } from "react";
import { EditRow } from "../../controlls/DetailTemplates/Headers";
import { useDataAttributeService } from "../../../services/useDataAttributeService";
import { GeneralJobForm } from "./GeneralJobForm";

export const GeneralJobView = ({ data, setData, onSave }) => {
  const [editMode, setEditMode] = useState(false);

  const { data: attributes } = useDataAttributeService({
    params: { attribute_type: "customer" },
  });

  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="md:flex md:items-center md:justify-between py-3">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Detailed Job Information
            </h3>
            <EditRow
              showCancel={true}
              editMode={editMode}
              onEdit={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={async () => {
                await onSave();
                setEditMode(false);
              }}
            />
          </div>
        </div>

        {editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <GeneralJobForm data={data} onChange={onChange} />
            </div>
          </>
        )}
        {!editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["name"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {+data["status"] === 0 && <span>Complete</span>}
                    {+data["status"] === 1 && <span>Open</span>}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Description
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["job_description"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Opportunity
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <a href={`/opportunities/${data["opportunity"]?.id}`}>
                      {data["opportunity"]?.title}
                    </a>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Account</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <a href={`/accounts/${data["account"]?.id}`}>
                      {data["account"]?.name}
                    </a>
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Property
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <a href={`/property/${data["property"]?.id}`}>
                      {data["property"]?.name}
                    </a>
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Job Start
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {moment(data["job_start"]).format("MM/DD/YYYY")}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Job End</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {moment(data["job_end"]).format("MM/DD/YYYY")}
                  </dd>
                </div>
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
