import axios from "axios";
import {authorizationService} from "./authorizationService";
import {settings} from "./settings";

export class ImportService {
    constructor(config={}){
        this.config = config;

    }
    async import_file(files, params, data){
        let formData = new FormData();
        files.forEach((file)=>{
            formData.append(data.import_table_name,file, file.name);
        });
        const {config} = authorizationService();
        let {data:result} = await axios.post(`${settings.url}/api/import_file/upload/?${this._prepareParams(params)}`,
            formData,{...config(),...this.getUploadHeaders()} );
        return result.data;
    }
    _prepareParams(params){
        return (Object.keys(params).map((item)=>{
            return `${item}=${params[item]}`
        }) || []).join('&');
    }

    getUploadHeaders(){
        return {headers :{},
            'Content-Type': 'multipart/form-data'};
    }

}
