import React from 'react';
export const DataView = ({cols, rows}) =>{
    return <div>
        <table className={'table table-striped valerian-table'}>
            <thead>
                <tr>
                    {cols.map((col, ind)=>{
                        return <th key={'col-header-' + ind}>{col}</th>;
                    })}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, row_ind)=>{
                    return <tr key={'data-row-' + row_ind}>{cols.map((col, col_index)=>{
                        return <td key={'data-row-' + row_ind + '_col_' + col_index}>{row[col]}</td>;
                    })}</tr>;
                })}
            </tbody>
        </table>
    </div>;
};