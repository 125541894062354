import { UsersList } from "../../components/Lists/UsersList";
import React, { useEffect, useState } from "react";
import { PButton } from "../../components/controlls/PButton";
import { Dialog } from "primereact/dialog";
import { UserService } from "../../services/UserService";

export const Users = () => {
  return (
    <div>
      <UsersList />
    </div>
  );
};
