import React from "react";

export const AddressForm = ({data, onChange}) =>{
    return <>
        <div className="form--group">
            <label htmlFor="">Address</label>
            <div className="form--group">
                <input placeholder={"Address 1"} name={"address1"} onChange={onChange} value={data["address1"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <input placeholder={"Address 2"} name={"address2"} onChange={onChange} value={data["address2"]} type="text" className="form--input"/>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <label htmlFor="">City</label>
                    <input name={"city"} onChange={onChange} type="text" value={data["city"]} className="form--input"/>
                </div>
                <div className={"col"}>
                    <label htmlFor="">State</label>
                    <input name={"state"} onChange={onChange}  value={data["state"]} type="text" className="form--input"/>
                </div>
                <div  className={"col"}>
                    <label htmlFor="">Zip</label>
                    <input name={"zip"} onChange={onChange}  value={data["zip"]}  type="text" className="form--input"/>
                </div>
            </div>
        </div>
    </>
}