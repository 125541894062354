import React, {Component, useEffect, useRef, useState} from 'react';
import Editor, {monaco} from '@monaco-editor/react';
import {DataManager} from "../../../services/data_manager";
import {settings} from "../../../services/settings";

export const QueryBuilder = () =>{
    const [rows, setRows] = useState([]);
    const [queryFields, setQueryFields] = useState([])
    const [message, setMessage] = useState("");
    const [failedMessage, setFailedMessage] = useState("")
    const [queryText, setQueryText] = useState("");

    const executeQuery = async () =>{
        let manager = new DataManager();

        let data = await manager.postData(`${settings.url}/api/import_file/query`,{query:queryText});
        if(data.fields && data.fields.length > 0 ){
            setFailedMessage("");
            setQueryFields(data.fields);
            setRows(data.rows);
            setMessage("Success");

        }else if(data.message){
            setMessage("");
            setQueryFields([]);
            setRows([]);
            setFailedMessage(`Failed: ${data.message}`);

        }
        else{
            setFailedMessage("");
            setQueryFields(data.fields);
            setRows(data.rows);
            setMessage("Complete");
        }
    }
    const handleEditorChange =(value)=>{
        setQueryText(value);
    }
    return <>
        <div>
            <div style={{marginBottom:'5px'}}>
                <h2 style={{fontSize:'1.5em'}}>SQL Editor &nbsp;</h2>
                <button className={'btn btn-primary'} onClick={executeQuery}>Execute</button>
            </div>
            <hr/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12" style={{padding: '5px', marginTop:'5px'}}>
                        <Editor height="30vh" language="sql"
                                value={queryText}
                                onChange={handleEditorChange}
                                />
                    </div>
                </div>
            </div>
            <hr/>
            <div style={{height:'43vh' ,overflow:'auto'}}>
                <div style={{color:'green'}}>{message}</div>
                <div style={{color:'red'}}>{failedMessage}</div>
                {rows && <ReportResultView rows={rows} fields={queryFields} />}
                {!rows ||  rows.length === 0 && (<div>
                    <p>Result will display here.</p>
                </div>)}
            </div>
        </div>
    </>
}

export const ReportResultView = ({rows,fields}) =>{

    return (
        <table className={'table sticky'}>
            <thead><tr>
                {fields.map((field, i)=>{
                    return (<th key={'result_head_' + i}>{field.name}</th>);
                })}
            </tr></thead>
            <tbody>
                {rows.map((row,row_i)=>{
                    return (<tr key={'row_num_' + row_i}>
                        {fields.map((field, i)=>{
                            return (<td key={'result_field_row_' + row_i + '_field_' + i}>{row[field.name]}</td>);
                        })}
                    </tr>);
                })}
            </tbody>
        </table>
    );

}
