import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { GeneralContactForm } from "../../components/management/contact/GeneralContactForm";
import { PButton } from "../../components/controlls/PButton";

export const AddAccountContact = ({
  accountId,
  save,
  setShowModal,
  showModal = false,
  currentRow,
  updated,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(currentRow || { contact: {} });

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  useEffect(() => {
    setData(currentRow);
  }, [currentRow]);
  const onSave = async () => {
    await save({ ...data, account_id: accountId });
    setData({ contact: {} });
    setShowModal(false);
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      {showModal && (
        <div className={"absolute top-0 left-0 w-full h-100 bg-white pb-2"}>
          <div className={"flex"}>
            <h2>Add Opportunity</h2>
          </div>
          <div>
            <GeneralContactForm data={data || {}} onChange={onChange} />
            <div>
              <PButton
                text={"save"}
                click={async () => {
                  await onSave();
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
