import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";
import {useEffect, useState} from "react";

export const useSupplierService = () =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        get().then((res)=>{

        });
    },[]);
    const get = async ()=>{
        setLoading(true)
        let {data:{suppliers}} = await axios.get(`${settings.url}/api/suppliers`,config());
        setLoading(false)
        setData(suppliers);
        return suppliers;
    }
    const {config} = authorizationService();
    return {
        loading,
        refresh:get,
        data:data,
        get:get,
        getOne:async (id)=>{
            let {data:{supplier}} = await axios.get(`${settings.url}/api/suppliers/${id}`,config());
            return supplier;
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/suppliers/${id}`,config());
            await get();
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/suppliers/${data.id}`,data,config()) :
                await axios.post(`${settings.url}/api/suppliers`,data,config());
            await get();
        }}
}