import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const useLaborTypesService = () =>{
    const {config} = authorizationService();
    return {
        get:async ()=>{
            let {data:{labor_types}} = await axios.get(`${settings.url}/api/labor_types`,config());
            return labor_types;
        },
        getOne:async (id)=>{
            let {data:{labor_type}} = await axios.get(`${settings.url}/api/labor_types/${id}`,config());
            return labor_type;
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/labor_types/${id}`,config());
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/labor_types/${data.id}`,data,config()) :
                await axios.post(`${settings.url}/api/labor_types`,data,config());
        }}
}