import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMessageFeedService } from "../../services/useMessageFeedService";
import moment from "moment";
import { Manager } from "socket.io-client";
import { settings } from "../../services/settings";
import { JobTextEditor } from "../../components/controlls/JobTextEditor";
import {
  MessageDisplay,
  MessageInput,
} from "../../components/controlls/MessagesComponents";

const isAtBottom = (elem) => {
  return elem?.scrollTop + elem?.offsetHeight === elem?.scrollHeight;
};
const scrollBottom = (elem) => {
  setTimeout(() => {
    elem?.scrollTo({
      top: elem?.scrollHeight,
      behavior: "auto",
    });
  }, 100);
};
export const MessageInterface = ({ context }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const scrollboxRef = useRef();
  const [isConnected, setIsConnected] = useState(false);
  const [lastPong, setLastPong] = useState(null);
  const [socket, setSocket] = useState(null);
  const { get, save } = useMessageFeedService({ params: { context: context } });
  useEffect(() => {
    const manager = new Manager(`${settings.ws_url}/`, {
      path: "/chat-service/",
    });
    const newSocket = manager.socket("/");
    newSocket.on("connect", () => {
      setIsConnected(true);
    });
    newSocket.on("disconnect", () => {
      setIsConnected(false);
    });
    newSocket.on("pong", () => {
      setLastPong(new Date().toISOString());
    });
    newSocket.on("message", async (msg) => {
      let res = await get();
      let onBottom = isAtBottom(scrollboxRef.current);
      setMessages(res);
      if (onBottom) scrollBottom(scrollboxRef.current);
    });
    newSocket.open();
    setSocket(newSocket);
    return () => {
      newSocket.off("connect");
      newSocket.off("disconnect");
      newSocket.off("message");
      newSocket.off("pong");
      newSocket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    get().then((res) => {
      setMessages(res);
      scrollBottom(scrollboxRef.current);
    });
    scrollboxRef.current?.addEventListener("scroll", (event) => {});
  }, []);

  const sendMessage = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    await save({
      from: `${user.first_name} ${user.last_name}`,
      name: "test",
      message: message,
      context: context,
    });
    setMessage("");
    let messageList = await get();
    setMessages(messageList);
    scrollBottom(scrollboxRef.current);
    return false;
  };

  return (
    <>
      <section
        aria-labelledby="notes-title"
        className={"overflow-hidden"}
        style={{ height: "70vh" }}
      >
        <div className="h-full bg-white shadow sm:rounded-lg overflow-hidden">
          <div className="h-full  divide-y divide-gray-200 ">
            <div
              ref={scrollboxRef}
              className=" px-4 py-2 sm:px-6 overflow-y-auto"
              style={{ height: "75%" }}
            >
              <ul role="list" className="space-y-8 ">
                {messages.map(({ id, message, from, created_at }) => (
                  <MessageDisplay
                    key={id}
                    comment={{ note: message, created_at, name: from }}
                  />
                ))}
              </ul>
            </div>
            <div className={"fixed bottom-0 w-full lg:pr-80 pr-10"}>
              <MessageInput
                message={message}
                setMessage={setMessage}
                onSave={sendMessage}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
