import {TabPanel, TabView} from "primereact/tabview";
import React, {useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useDataAttributeService} from "../../services/useDataAttributeService";
import {PButton} from "../../components/controlls/PButton";
import {Dialog} from "primereact/dialog";

export const ContentManager = () =>{
    const [activeIndex, setActiveIndex] = useState(0);
    const {data,save,deleteData} = useDataAttributeService({});
    const [item, setItem] = useState({});
    const [showModal, setShowModal] = useState(false);
    const onSave = async () =>{
        await save({...item});
        setItem({});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        setItem( {...item,[name]:value});

    }
    const editBodyTemplate = (row) =>{
        return <button className={"btn btn--secondary"} onClick={async ()=>{
            await deleteData(row.id);
        }}><i className={"fas fa-trash"}></i></button>
    }
    return <div>
        <TabView className={"full-tab-view"}  activeIndex={activeIndex}
                 onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Attributes">
                <div>
                    <button className={"btn btn--primary"} onClick={()=>{
                        setShowModal(true);
                    }}>Add</button>
                    <Dialog header="Add Supplier" style={{ width: '50vw' }}  visible={showModal} onHide={() => setShowModal(false)}>
                        <div className="form--group">
                            <label htmlFor="">Attribute Type</label>
                            <select name={"attribute_type"} onChange={onChange} value={data["attribute_type"]}  className="form--input">
                                <option value="">--Select One</option>
                                <option value="customer">Customer</option>
                                <option value="contact">Contact</option>
                                <option value="opportunity">Opportunity</option>
                                <option value="job">Job</option>
                            </select>
                        </div>
                        <div className="form--group">
                            <label htmlFor="">Attribute Name</label>
                            <input name={"attribute_name"} onChange={onChange} value={data["attribute_name"]}  className="form--input" />
                        </div>
                        <div className="form--group">
                            <label htmlFor="">Attribute Display</label>
                            <input name={"attribute_display"} onChange={onChange} value={data["attribute_display"]}  className="form--input" />
                        </div>
                        <div>
                            <PButton text={"save"} click={async ()=>{
                                await onSave();
                            }}/>
                        </div>
                    </Dialog>
                </div>

                <DataTable value={data} responsiveLayout="scroll">
                    <Column field="attribute_type" header="Attribute Type"></Column>
                    <Column field="attribute_name" header="Name"></Column>
                    <Column field="attribute_display" header="Display"></Column>
                    <Column field="" header="Edit" body={editBodyTemplate}></Column>

                </DataTable>

            </TabPanel>
        </TabView>
    </div>
}