import {useProficiencyGroupService} from "../../services/proficiencies/useProficiencyGroupService";
import React, {useState} from "react";
import {ProficiencyGroupAdd} from "./components/ProficiencyGroupAdd";
import {TabPanel, TabView} from "primereact/tabview";
import {ProficiencyCategories} from "./components/ProficiencyCategories";
import {ProficiencyRating} from "./components/ProficiencyRating";

export const ServiceGroups = ({selectedKey}) =>{
    const {save:saveGroup, data:groups} = useProficiencyGroupService({params:{vendor_category_id:(selectedKey || 0)},context:[selectedKey]});
    const [activeIndex, setActiveIndex] = useState(0);
    return <> <h1>Service Groups <ProficiencyGroupAdd save={saveGroup} vendor_category_id={selectedKey}></ProficiencyGroupAdd></h1>

        {!groups || groups.length === 0 && <>
            <h3>No Groups Defined</h3>
        </>}
        {groups && <>
            <TabView className={"full-tab-view"}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {groups.map((item, index)=>{
                    return <TabPanel key={`tab-item-${index}`} header={item.name}>
                        <div className={"display-row"}>
                            <div className={"display-column panel"}>
                                <ProficiencyCategories group={item}></ProficiencyCategories>
                            </div>
                            <div className={"display-column panel"}>
                                <ProficiencyRating group={item}></ProficiencyRating>
                            </div>
                        </div>
                    </TabPanel>
                })}
            </TabView>
        </>}</>
}
