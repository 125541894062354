import React from "react";

export  const VendorContactView = ({data}) =>{
    return <div>

    <div className="form">
        <div className="form--group">
            <label htmlFor="">First Name</label>
            <div  className={"data-view-display"}>{data["first_name"]}</div>
        </div>

        <div className="form--group">
            <label htmlFor="">Last Name</label>
            <div  className={"data-view-display"}>{data["last_name"]}</div>
        </div>
        <div className="form--group">
            <label htmlFor="">Email</label>
            <div  className={"data-view-display"}>{data["email"]}</div>
        </div>
        <div className="form--group">
            <label htmlFor="">Phone</label>
            <div  className={"data-view-display"}>{data["phone"]}</div>
        </div>
        <div className="form--group">
            <label htmlFor="">Secondary Phone</label>
            <div  className={"data-view-display"}>{data["phone_secondary"]}</div>
        </div>
        </div>
    </div>
}