import {authorizationService} from "./authorizationService";
import axios from "axios";
import {settings} from "./settings";
import {useEffect, useState} from "react";

export const useContactService = () =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        get().then((res)=>{

        });
    },[]);
    const get = async ()=>{
        setLoading(true)
        let {data:{contacts}} = await axios.get(`${settings.url}/api/contacts`,config());
        setLoading(false)
        setData(contacts);
        return contacts;
    }
    const {config} = authorizationService();
    return {
        loading,
        get:get,
        refresh:get,
        data:data,
        getOne:async (id)=>{
            let {data:{contact}} = await axios.get(`${settings.url}/api/contacts/${id}`,config());
            return contact;
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/contacts/${id}`,config());
            await get();
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/contacts/${data.id}`,data,config()) :
                await axios.post(`${settings.url}/api/contacts`,data,config());
            await get();
        }}
}