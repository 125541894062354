import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const useSupplierTypesService = () =>{
    const {config} = authorizationService();
    return {get:async ()=>{
            let {data:{supplier_types}} = await axios.get(`${settings.url}/api/supplier_types`,config());
            return supplier_types;
        },
        getOne:async (id)=>{
            let {data:{supplier_type}} = await axios.get(`${settings.url}/api/supplier_types/${id}`,config());
            return supplier_type[0];
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/supplier_types/${id}`,config());
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/supplier_types/${data.id}`,data,config()) :
                await axios.post(`${settings.url}/api/supplier_types`,data,config());
        }};
}