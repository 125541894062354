import {authorizationService} from "./authorizationService";
import axios from "axios";
import {settings} from "./settings";

export const usePropertyTypesService = () =>{
    const {config} = authorizationService();
    return {get:async ()=>{
            let {data:{property_types}} = await axios.get(`${settings.url}/api/property_types`,config());
            return property_types;
        },
        getOne:async (id)=>{
            let {data:{property_type}} = await axios.get(`${settings.url}/api/property_types/${id}`,config());
            return property_type[0];
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/property_types/${id}`,config());
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/property_types/${data.id}`,data,config()) :
                await axios.post(`${settings.url}/api/property_types`,data,config());
        }};
}