import React, {useEffect, useState} from "react";
import {useSupplierService} from "../../services/useSupplierService";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../components/controlls/PButton";
import {useJobVendorService} from "../../services/useJobVendorService";
import {useVendorService} from "../../services/useVendorService";

export const AddJobVendor = ({jobId,save,setShowModal, showModal=false,currentRow, updated}) =>{
    const [data, setData] = useState(currentRow);
    const {data:vendors} = useVendorService({});

    useEffect(()=>{
        setData(currentRow);
    },[currentRow])
    const onSave = async () =>{
        await save({...data,job_id:jobId});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});

    }
    return <div>
        <Dialog header="Add Supplier" style={{ width: '50vw' }}  visible={showModal} onHide={() => setShowModal(false)}>
            <div className="form--group">
                <label htmlFor="">Vendor</label>
                <select name={"vendor_id"} onChange={onChange} value={data["vendor_id"]}  className="form--input">
                    <option value="">--Select One</option>
                    {vendors.map((item, index)=>{
                        return <option key={"vendor_" + index} value={item.id}> {item.name} </option>
                    })}
                </select>
            </div>
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}