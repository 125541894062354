import { serialize } from "../../pages/messaging/Serializer";
import { Toolbar } from "../../pages/messaging/EditorComponents";
import {
  BlockButton,
  Element,
  HOTKEYS,
  Leaf,
  MarkButton,
  toggleMark,
} from "../../pages/messaging/EditorFormatters";
import { Editable, Slate, withReact } from "slate-react";
import isHotkey from "is-hotkey";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createEditor, Transforms } from "slate";

export const JobTextEditor = ({ message, setMessage }) => {
  const editor = useMemo(() => withReact(createEditor()), []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const renderElement = useCallback((props) => <Element {...props} />, []);

  const [messageData, setMessageData] = useState([
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
  ]);
  useEffect(() => {
    if (message === "") {
      Transforms.removeNodes(editor, {
        at: [0],
      });
      Transforms.insertNodes(
        editor,
        { type: "paragraph", children: [{ text: "" }] },
        { at: [editor.children.length] }
      );
    }
  }, [message]);
  return (
    <>
      <Slate
        editor={editor}
        value={messageData}
        onChange={(value) => {
          const isAstChange = editor.operations.some(
            (op) => "set_selection" !== op.type
          );
          if (isAstChange) {
            setMessage(serialize(value));
          }
        }}
      >
        <Toolbar>
          <div className={"flex-shrink"}>
            <MarkButton format="bold" icon="fa-bold" />
            <MarkButton format="italic" icon="fa-italic" />
            <MarkButton format="underline" icon="fa-underline" />
            <MarkButton format="code" icon="fa-code" />
          </div>
          <div>
            <BlockButton format="heading-one" icon="fa-h1" />
            <BlockButton format="heading-two" icon="fa-h2" />
            <BlockButton format="block-quote" icon="fa-quote" />
            <BlockButton format="numbered-list" icon="fa-list-ol" />
            <BlockButton format="bulleted-list" icon="fa-list-ul" />
            <BlockButton format="left" icon="fa-align-left" />
            <BlockButton format="center" icon="fa-align-center" />
            <BlockButton format="right" icon="fa-align-right" />
            <BlockButton format="justify" icon="fa-align-justify" />
          </div>
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          value={message}
          placeholder="Enter a message…"
          spellCheck
          autoFocus
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </>
  );
};
