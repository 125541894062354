import React, {cloneElement, isValidElement, useEffect, useRef, useState} from "react";
import {useDeepCompareEffectForMaps} from "./useDeepCompare";

export const Map = ({
                onLoad,
                onUnmount,
                 onClick,
                 onIdle,
                 children,
                 style,
                 ...options
             }) => {
    const ref = useRef(null);
    const [map, setMap] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map]);

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options);
        }
    }, [map, options]);

    useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );
            if (onClick) {
                map.addListener("click", onClick);
            }
            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
            if (onLoad) {
                map.addListener("onLoad", () => onLoad(map));
            }
            if (onUnmount) {
                map.addListener("onUnmount", () => onUnmount(map));
            }

        }
    }, [map, onClick, onIdle]);

    return (
        <>
            <div ref={ref} style={style} className={"map-canvas"} />
            {React.Children.map(children, (child) => {
                if(map && child){

                }
                if (isValidElement(child)) {
                    return cloneElement(child, { map });
                }
            })}
        </>
    );
};
