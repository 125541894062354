import { settings } from "../../../services/settings";
import React, { useState } from "react";
import axios from "axios";

export const ImageViewer = ({ attachments = [], onSave, onDelete }) => {
  const [file, setFile] = useState();
  const [isNew, setIsNew] = useState(false);
  const [imageShown, setImageShown] = useState(0);

  const [fileKey, setFileKey] = useState(Math.random().toString(36));
  const [isSelected, setIsSelected] = useState(false);
  const onFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setIsSelected(true);
    }
  };
  const onUpload = async (event) => {
    const formData = new FormData();
    formData.append("uploadedFile", file, file.name);
    onSave(formData);
    setFile(undefined);
    setFileKey(Math.random().toString(36));
    setIsSelected(false);
    setIsNew(false);
  };
  const getCurrentImage = () => {
    return attachments[imageShown];
  };
  let attachment = getCurrentImage();
  return (
    <div>
      {isNew && (
        <>
          <label className="file">
            <input
              type="file"
              className={""}
              key={fileKey}
              onChange={(e) => {
                onFileChange(e);
              }}
            />
            <span className="file-custom">
              {file?.name || "Choose file..."}
            </span>
          </label>
          {isSelected ? (
            <div className={"file-details"}>
              <div>
                <label htmlFor="">Filename:</label> {file.name}
              </div>
              <div>
                <label htmlFor="">Filetype:</label> {file.type}
              </div>
              <div>
                <label htmlFor="">Size in bytes:</label> {file.size}
              </div>
              <div>
                <label htmlFor="">Last Modified: </label>
                {file.lastModifiedDate.toLocaleDateString()}
              </div>
            </div>
          ) : (
            <div>Select a file to show details</div>
          )}
          {file && (
            <>
              <button className={"btn btn--primary"} onClick={onUpload}>
                Upload
              </button>
              <button
                className={"btn btn--primary"}
                onClick={() => {
                  setFile(undefined);
                  setFileKey(Math.random().toString(36));
                  setIsSelected(false);
                }}
              >
                Clear
              </button>
            </>
          )}
          {!file && (
            <>
              <button
                className={"btn btn--primary"}
                onClick={() => {
                  setFile(undefined);
                  setFileKey(Math.random().toString(36));
                  setIsSelected(false);
                  setIsNew(false);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </>
      )}
      {!isNew && (
        <>
          <button
            className={"btn btn--primary"}
            onClick={() => {
              setIsNew(true);
            }}
          >
            New
          </button>
        </>
      )}
      <div>
        {attachment && (
          <>
            <div key={`attachment_id_${attachment.id}`}>
              <img
                className={"attachment-viewer--image"}
                src={`${settings.url}/api/properties/file/${attachment.property_id}/${attachment.id}/${attachment.name}`}
                alt=""
              />
            </div>
            <button
              className={"btn btn--primary"}
              onClick={async () => {
                setImageShown(0);
                await onDelete(attachment.id);
              }}
            >
              Remove
            </button>
          </>
        )}
      </div>
      {attachments.length === 0 && (
        <div className={"no-image"}>
          <i className={"fas fa-image"}></i>
        </div>
      )}
      {attachments.length > 0 && (
        <div className={"image-nav absolute z-10 right-0"}>
          <div className={"image-nav--title"}>
            showing {imageShown + 1} of {attachments.length}
          </div>
          <div className={"image-nav--buttons"}>
            {imageShown > 0 && (
              <button
                onClick={() => {
                  setImageShown(imageShown - 1);
                }}
              >
                <i className={"fas fa-arrow-left"}></i>
              </button>
            )}
            {imageShown < attachments.length - 1 && (
              <button
                onClick={() => {
                  setImageShown(imageShown + 1);
                }}
              >
                <i className={"fas fa-arrow-right"}></i>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
