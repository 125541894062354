import {UserService} from "../../services/UserService";
import {useState} from "react";
import {required, useValidations} from "../../services/validations";
import {InputField} from "../../components/controlls/InputField";
import {PasswordField} from "../../components/controlls/PasswordField";
import {useNavigate} from "react-router-dom";

export const Login = () =>{
    const {authenticate} = UserService();
    const [data, setData] = useState({password:'', username:''});
    const [validationState, setValidationState] = useState({});
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const update = ({target:{name, value}}) =>{
        setData({...data,...{[name]:value}});
    }

    const form = useValidations({
        'password': {
            validations: [
                {validation: required, message: "Must Enter Password"},

            ]
        },
        'username': {
            validations: [
                {validation: required, message: "Enter Username"}
            ]
        },
    });
    const save = async () =>{
        if(!form.isValid(data))
        {
            setValidationState(form.getValidState(data));
            return;
        }
        let result = await authenticate({...data});
        if(result.authenticated){
            localStorage.setItem("user",JSON.stringify(result.user));
            localStorage.setItem("token",result.token);
            navigate("/");
        }
        else{
            setMessage("Login Failed")
        }
    }
    return  <div className={"login-layout panel"}>
        <div className={"error"}>
            {message}
        </div>
        <div className={"login-layout--title"}>Login</div>
        <div className={"form--group"}>
            <label htmlFor="">Username</label>
            <InputField valid={validationState.username}
                           validations={form.def.username.validations}
                           onChange={update}
                           name={"username"}
                           value={data["username"]}
                           placeholder={"Username"} obj={data}/>
        </div>
        <div className={"form--group"}>
            <label htmlFor="">Password</label>
            <PasswordField valid={validationState.password}
                           validations={form.def.password.validations}
                           onChange={update}
                           name={"password"}
                           value={data["password"]}
                           placeholder={"Password"} obj={data}/>
        </div>

        <div className={"form--group"}>
            <button className={"btn btn--primary"} onClick={async ()=> await save()}>Login</button>
        </div>
    </div>
}