import React, { useEffect, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import { Link } from "react-router-dom";

import { PButton } from "../controlls/PButton";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useContactService } from "../../services/useContactService";
import { AddContact } from "../management/contact/AddContact";
import { useListHelper } from "../../hooks/useListHelper";
import { basicParams } from "../../common/listUtils";

export const ContactList = () => {
  const { data: contacts, loading, deleteData, save } = useContactService();
  const {
    renderHeader,
    actionBodyTemplate,
    filters,
    show,
    setShow,
    currentRow,
  } = useListHelper({
    title: "Contacts",
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      first_name: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      last_name: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      email: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      phone: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      secondary_phone: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    },
    onDelete: async (row) => {
      await deleteData(row.id);
    },
  });

  const detailsLinkTemplate = (rowData) => {
    return <Link to={`/contact/${rowData.id}`}>{rowData.last_name}</Link>;
  };
  const dtParams = {
    ...basicParams,
    value: contacts,
    filterDisplay: "row",
    responsiveLayout: "scroll",
    dataKey: "id",
    paginator: true,
    lazy: true,
    rows: 10,
    filters: filters,
    totalRecords: 1000,

    header: renderHeader(),

    loading: loading,
    globalFilterFields: [
      "first_name",
      "last_name",
      "email",
      "phone",
      "phone_secondary",
      "status",
    ],
    emptyMessage: "No contacts found.",
  };

  const columnExp = [
    {
      field: "last_name",
      header: "Last Name",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Last Name",
      style: { minWidth: "14rem" },
      body: detailsLinkTemplate,
    },
    {
      field: "first_name",
      header: "First Name",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by First Name",
      style: { minWidth: "14rem" },
    },
    {
      field: "email",
      header: "Email",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Email",
      filterField: "email",
      style: { minWidth: "14rem" },
    },
    {
      field: "phone",
      header: "Phone",
      sortable: true,
      filter: true,
      sortField: "phone",
      filterField: "phone",
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
    {
      field: "phone_secondary",
      header: "Phone Secondary",
      sortable: true,
      filter: true,
      sortField: "phone_secondary",
      filterField: "phone_secondary",
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
  ];
  return (
    <div className="datatable-doc-demo">
      <div className={"data-action-row"}>
        <PButton click={() => setShow(true)} text={"Add"} />
        <AddContact
          save={save}
          setShowModal={setShow}
          showModal={show}
          currentRow={currentRow}
        >
          {" "}
        </AddContact>
      </div>
      <div className="card">
        <DataTable {...dtParams}>
          {columnExp.map((item, index) => {
            return <Column key={"column-val-" + index} {...item} />;
          })}
          <Column
            headerStyle={{ textAlign: "center" }}
            filter={false}
            bodyStyle={{
              minWidth: "11rem",
              textAlign: "center",
              overflow: "visible",
            }}
            body={actionBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
};
