import {Header} from "../components/Header";
import {LeftMenu} from "../components/LeftMenu";
import {Body} from "../components/Body";
import {Footer} from "../components/Footer";
import React from "react";
import {Outlet} from "react-router-dom";

export const UnauthLayout = ({children}) =>{
    return <>
        <div className="main">
            <Header></Header>
            <div className={"middle-container"}>
                 <Body><Outlet/></Body>
            </div>
            <Footer></Footer>
        </div>
</>
}