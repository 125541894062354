import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../controlls/PButton";
import {useContactService} from "../../../services/useContactService";
import {GeneralContactForm} from "./GeneralContactForm";

export const AddContact = ({save,setShowModal, showModal=false,currentRow, updated}) =>{
    const [show, setShow] = useState(false);
    const [data, setData] = useState(currentRow);

    useEffect(()=>{
        setShow(showModal)
    },[showModal])
    useEffect(()=>{
        setData(currentRow);
    },[currentRow])
    const onSave = async () =>{
        await save({...data});
        setData({});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});
    }
    return <div>
        <Dialog header="Add Contact" style={{ width: '50vw' }}  visible={show} onHide={() => setShowModal(false)}>
            <GeneralContactForm data={data} onChange={onChange} />
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}