import React, { useEffect, useState } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useJobService } from "../../services/useJobService";
import { AddJob } from "../management/job/AddJob";
import moment from "moment";
import { useListHelper } from "../../hooks/useListHelper";

import {
  ChevronRightIcon,
  EnvelopeIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  UserIcon,
  CalendarIcon,
  BriefcaseIcon,
} from "@heroicons/react/20/solid";
import InfiniteScroll from "react-infinite-scroll-component";
export const JobList = () => {
  const [jobStatus, setJobStatus] = useState("1");
  const {
    pagination,
    data: jobs,
    deleteData,
    save,
    onChange,
    hasMore,
    addNextPage,
  } = useJobService({ params: { status: jobStatus }, context: [jobStatus] });
  const { show, setShow, currentRow, onGlobalFilterChange } = useListHelper({
    title: "Jobs",
    pagination,
    onChange,
    globalFields: ["name"],
    filters: {
      global: { value: "", matchMode: FilterMatchMode.CONTAINS },
    },
    onDelete: async (row) => {
      await deleteData(row.id);
    },
  });
  return (
    <>
      <div className="">
        <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Jobs
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                onClick={() => setShow(true)}
                type="button"
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create new job
              </button>
            </div>
          </div>
        </div>
        <div className="py-4 bg-white  flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full w-full border-0 py-0 pl-5 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              onChange={onGlobalFilterChange}
            />
          </form>
        </div>
        <select
          onChange={(event) => {
            if (!event.target.value) setJobStatus(undefined);
            else setJobStatus(event.target.value);
          }}
        >
          <option value={"1"}>Open</option>
          <option value={""}>All</option>
        </select>

        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            <InfiniteScroll
              dataLength={jobs.length}
              next={addNextPage}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Showing All Results</b>
                </p>
              }
            >
              {jobs.map((job) => (
                <li key={job.id}>
                  <a
                    href={`/jobs/${job.id}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="flex min-w-0 flex-1 items-center">
                        <div className="flex-shrink-0">
                          <BriefcaseIcon
                            className="mr-1.5 h-10 w-10 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {job.name}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <GlobeAltIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                {job.property?.name}
                              </span>
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <UserIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                {job.primary_contact?.first_name}{" "}
                                {job.primary_contact?.last_name}
                              </span>
                            </p>
                            <div className="block md:hidden">
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <PhoneIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Starts: </span>
                                <phone>
                                  {moment(job.job_start).format("MM/DD/YYYY")}
                                </phone>
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <GlobeAltIcon
                                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span>Ends: </span>
                                <span className="truncate">
                                  {moment(job.job_end).format("MM/DD/YYYY")}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <CalendarIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Starts: </span>
                              <phone>
                                {moment(job.job_start).format("MM/DD/YYYY")}
                              </phone>
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <CalendarIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span>Ends: </span>
                              <span className="truncate">
                                {moment(job.job_end).format("MM/DD/YYYY")}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </InfiniteScroll>
          </ul>
        </div>
      </div>
      <div className="datatable-doc-demo">
        <div className={"data-action-row"}>
          <AddJob
            save={save}
            setShowModal={setShow}
            showModal={show}
            currentRow={currentRow}
          >
            {" "}
          </AddJob>
        </div>
      </div>
    </>
  );
};
