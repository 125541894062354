import React, {useEffect, useRef, useState} from "react";
import {Calendar} from "@fullcalendar/core";
import interaction from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button, ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container, Form,
    FormGroup, Input,
    Modal,
    Row
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import {EventModal} from "./EventModal";
import {AddEventModal} from "./AddEventModal";
import {useJobTaskService} from "../../services/useJobTaskService";
let calendar = null;
export const MainCalendar = () =>{
    const [events, setEvents] = useState([]);
    const [alert, setAlert] = useState(null);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalChange, setModalChange] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [radios, setRadios] = useState(null);
    const [eventId, setEventId] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);
    const [eventDescription, setEventDescription] = useState(null);
    const {data} = useJobTaskService({});
    // eslint-disable-next-line
    const [event, setEvent] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);

    const [refresh, setRefresh] = useState(false);

    const calendarRef = useRef(null);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) {
            console.log("nu eee");
            return;
        }
        const {_id: userId} = user;
        const runAsync = async () => {

            //const {data} = await getTasksByUser(userId);
            const tasks = [];
            console.log(tasks);
            setEvents(data.map(task => ({
                id: task.id,
                start: new Date(task.due_date),
                end: new Date(new Date(task.due_date) - ( 3600 * 1000 * 24)),
                title: task.title,
                description: task.description,
                className: task.color,
                allDay: true
            })))
        }
        runAsync();
        // eslint-disable-next-line
    }, [refresh,data]);

    useEffect(() => {
        createCalendar();
    }, [events]);

    const createCalendar = () => {
        calendar = new Calendar(calendarRef.current, {
            plugins: [interaction, dayGridPlugin],
            initialView: "dayGridMonth",
            selectable: true,
            editable: true,
            events: events,
            headerToolbar: "",
            // Add new event
            select: (info) => {
                setModalAdd(true);
                setStartDate(info.startStr);
                setEndDate(info.endStr);
                setRadios("bg-info");
            },
            // Edit calendar event action
            eventClick: ({event}) => {
                console.log(event.id, event.extendedProps);
                setEventId(event.id);
                setEventTitle(event.title);
                setEventDescription(event.extendedProps.description);
                setRadios("bg-info");
                setEvent(event);
                setModalChange(true);
            },
        });
        calendar.render();
        setCurrentDate(calendar.view.title);
    };
    const changeView = (newView) => {
        calendar.changeView(newView);
        setCurrentDate(calendar.view.title);
    };

    return (
        <>
            {alert}
            <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            <Col lg="6">
                                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                                    {currentDate}
                                </h6>
                                <Breadcrumb
                                    className="d-none d-md-inline-block ml-lg-4"
                                    listClassName="breadcrumb-links breadcrumb-dark"
                                >
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            Dashboard
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem aria-current="page" className="active">
                                        Calendar
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                            <Col className="mt-3 mt-md-0 text-md-right" lg="6">
                                <Button
                                    className="fullcalendar-btn-prev btn-neutral"
                                    color="default"
                                    onClick={() => {
                                        calendar.prev();
                                        setCurrentDate(calendar.view.title);
                                    }}
                                    size="sm"
                                >
                                    <i className="fas fa-angle-left" />
                                </Button>
                                <Button
                                    className="fullcalendar-btn-next btn-neutral"
                                    color="default"
                                    onClick={() => {
                                        calendar.next();
                                        setCurrentDate(calendar.view.title);
                                    }}
                                    size="sm"
                                >
                                    <i className="fas fa-angle-right" />
                                </Button>
                                <Button
                                    className="btn-neutral"
                                    color="default"
                                    data-calendar-view="month"
                                    onClick={() => changeView("dayGridMonth")}
                                    size="sm"
                                >
                                    Month
                                </Button>
                                <Button
                                    className="btn-neutral"
                                    color="default"
                                    data-calendar-view="basicWeek"
                                    onClick={() => changeView("dayGridWeek")}
                                    size="sm"
                                >
                                    Week
                                </Button>
                                <Button
                                    className="btn-neutral"
                                    color="default"
                                    data-calendar-view="basicDay"
                                    onClick={() => changeView("dayGridDay")}
                                    size="sm"
                                >
                                    Day
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Container className="" fluid>
                <Row>
                    <div className="col">
                        <Card className="card-calendar">
                            <CardHeader>
                                <h5 className="h3 mb-0">Calendar</h5>
                            </CardHeader>
                            <CardBody className="p-0">
                                <div
                                    className="calendar"
                                    data-toggle="calendar"
                                    id="calendar"
                                    ref={calendarRef}
                                />
                            </CardBody>
                        </Card>
                        <AddEventModal modalAdd={modalAdd} setModalAdd={setModalAdd} save={()=>{}} />
                        <EventModal modalChange={modalChange} setModalChange={setModalChange} setAlert={setAlert}/>
                    </div>
                </Row>
            </Container>
        </>);

}