import React, {useEffect, useState} from "react";
import {Tree} from "primereact/tree";
import {VendorCategoryAdd} from "./VendorCategoryAdd";

export const CategoryManager = ({saveCategory, categories,selectedKey,setSelectedKey}) => {
    const [nodes, setNodes] = useState([]);
    const [selectedNode, setSelectedNode] = useState({key:0});
    const [selectedItem, setSelectedItem] = useState({key:0});

    const getChildren = (parent)=>{
        let children = categories.filter(i=>i.parent_vendor_category_id === parent.id);
        children.forEach((child)=>{
            child.children = getChildren(child);
        });
        return children.map((child)=>{
            return {
                "key": child.id,
                "label": child.name,
                "data": child.name,
                leaf: !!child.children,
                children: child.children
            }
        });
    }
    useEffect(() => {
        let parents = categories.filter(i=>i.parent_vendor_category_id === 0);
        let nodes = parents.map((category, index)=>{
            return {
                "id":category.id,
                "key": category.id,
                "label": category.name,
                "data": category.name,
                leaf: false.valueOf(),
                children: getChildren(category)
            }
        })
        setNodes(nodes || []);
    }, [categories]);

    const onNodeSelect = (event) => {
        setSelectedItem(categories.find(i=>i.id === event.node.key))
        setSelectedNode(event.node);
    }

    const onNodeUnselect = (node) => {
        setSelectedNode({key:0});
    }
    const updateParent = (target,list, new_children)=>{
        let parent = list.find(i=> i.key === target.key);
        if(parent){
            parent.children = new_children;
            return;
        }

        list.forEach((child)=>{
            updateParent(target, (child.children || []), new_children);
        })
    }
    return (
        <div className={"panel"}>
            <h5>Vendor Categories </h5>
            <div>
                <button className={"btn btn--primary"} onClick={()=>{
                    setSelectedNode({key:0});
                    setSelectedKey(null)
                    setSelectedItem({})
                }}>Clear</button>
            </div>
            <br/>

            <VendorCategoryAdd save={saveCategory} item={selectedItem} selectedNode={selectedNode}></VendorCategoryAdd>
            <Tree value={nodes} selectionMode="single"
                  selectionKeys={selectedKey}
                  onSelectionChange={e => setSelectedKey(e.value)}
                  onSelect={onNodeSelect}
                  onUnselect={onNodeUnselect} />
        </div>
    )
}