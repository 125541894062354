import {useEffect, useState} from "react";
import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const usePropertyAttachments = ({property_id}) =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        get().then((res)=>{

        });
    },[]);
    const get = async ()=>{
        setLoading(true)
        let {data:{property_attachments}} = await axios.get(`${settings.url}/api/property_attachments?property_id=${property_id}`,config());
        setLoading(false)
        setData(property_attachments);
        return property_attachments;
    }
    const {config} = authorizationService();
    return {
        loading,
        refresh:get,
        data:data,
        get:get,
        getOne:async (id)=>{
            let {data:{job_supplier}} = await axios.get(`${settings.url}/api/property_attachments/${id}`,config());
            return job_supplier;
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/property_attachments/${id}`,config());
            await get();
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/property_attachments/${data.id}`,{...data,property_id:property_id},config()) :
                await axios.post(`${settings.url}/api/property_attachments`,{...data,property_id:property_id},config());
            await get();
        }}
}