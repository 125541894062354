import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { GeneralOpportunityForm } from "../opportunity/GeneralOpportunityForm";
import { PButton } from "../../controlls/PButton";
import { GeneralAccountForm } from "./GeneralAccountForm";

export const AddAccount = ({
  save,
  setShowModal,
  showModal = false,
  currentRow,
  updated,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(currentRow);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  useEffect(() => {
    setData(currentRow);
  }, [currentRow]);

  const onSave = async () => {
    await save(data);
    setData({});
    setShowModal(false);
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <>
      {showModal && (
        <div className={"absolute top-0 left-0 w-full h-100 bg-white"}>
          <div className={"flex"}>
            <h2>Add Account</h2>
          </div>
          <div>
            <GeneralAccountForm data={data || {}} onChange={onChange} />
          </div>

          <div>
            <PButton
              text={"cancel"}
              click={async () => {
                setShowModal(false);
              }}
            />
            <PButton
              text={"save"}
              click={async () => {
                await onSave();
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
