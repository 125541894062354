import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authorizationService } from "../../services/authorizationService";
import { TabPanel, TabView } from "primereact/tabview";
import { PButton } from "../../components/controlls/PButton";
import { BasicApiMap } from "../../components/controlls/Maps/BasicApiMap";
import { GeneralAccountForm } from "../../components/management/account/GeneralAccountForm";
import { AccountDetailView } from "./AccountView";
import { useAccountService } from "../../services/useAccountService";
import { AccountProperties } from "./AccountProperties";
import { AccountContacts } from "./AccountContacts";
import { AccountNotes } from "./AccountNotes";
import { AccountOpportunities } from "./AccountOpportunities";
import { TabSelector } from "../../components/controlls/TabSelector";
import {
  EditRow,
  ViewHeader,
} from "../../components/controlls/DetailTemplates/Headers";
const tabs = [
  { name: "Details", key: "details", href: "#", current: true },
  { name: "Map", href: "#", current: false },
  { name: "Properties", href: "#", current: false },
  { name: "Contacts", href: "#", current: false },
  { name: "Notes", href: "#", current: false },
  { name: "Opportunities", href: "#", current: false },
];

export const AccountDetails = () => {
  let { accountId } = useParams();
  const [data, setData] = useState({ lat: 0, lng: 0 });
  const [editMode, setEditMode] = useState(false);
  const { save, getOne } = useAccountService({});
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  useEffect(() => {
    if (accountId) {
      getOne(accountId).then((res) => {
        setData(res);
      });
    }
  }, [accountId]);
  const onSave = async () => {
    await save({ ...data });
    setEditMode(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="md:flex md:items-center md:justify-between py-3">
        <ViewHeader title={`Account: ${data.name}`} />
      </div>

      <TabSelector
        items={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      ></TabSelector>

      {activeIndex === 0 && (
        <>
          <div className={"py-3"}>
            <AccountDetailView
              accountId={accountId}
              data={data}
              setData={setData}
              onSave={onSave}
            />
          </div>
        </>
      )}
      {activeIndex === 1 && (
        <div className={" panel"}>
          <BasicApiMap locationItems={[...(data?.properties || [])]} />
        </div>
      )}
      {activeIndex === 2 && (
        <AccountProperties accountId={accountId}></AccountProperties>
      )}
      {activeIndex === 3 && (
        <AccountContacts accountId={accountId}></AccountContacts>
      )}
      {activeIndex === 4 && <AccountNotes accountId={accountId}></AccountNotes>}
      {activeIndex === 5 && (
        <AccountOpportunities accountId={accountId}></AccountOpportunities>
      )}
    </div>
  );
};
