import { PButton } from "../../components/controlls/PButton";
import React from "react";

export const UsersAdd = ({
  showModal,
  data,
  update,
  saveUser,
  setShow,
  resendWelcome,
}) => {
  return (
    <>
      {showModal && (
        <div className={"absolute top-0 left-0 w-full h-100 bg-white"}>
          <div className={"flex"}>
            <h2>Add User</h2>
          </div>

          <div className="form">
            <div className="form--group">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name={"email"}
                className="form--input"
                onChange={update}
                value={data["email"]}
              />
            </div>
            <div className="form--group">
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name={"first_name"}
                className="form--input"
                onChange={update}
                value={data["first_name"]}
              />
            </div>
            <div className="form--group">
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name={"last_name"}
                className="form--input"
                onChange={update}
                value={data["last_name"]}
              />
            </div>
            <div className="form--group">
              <label htmlFor="">Active</label>
              <div>
                <input
                  type="checkbox"
                  name={"active"}
                  className=""
                  onChange={update}
                />
              </div>
            </div>
          </div>

          <div>
            <PButton
              text={"resend welcome"}
              click={async () => {
                await resendWelcome(data);
              }}
            />
            <PButton
              text={"cancel"}
              click={async () => {
                setShow(false);
              }}
            />
            <PButton
              text={"save"}
              click={async () => {
                await saveUser();
                setShow(false);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
