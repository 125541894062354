import React, {useState} from 'react';
import {Navigate, Outlet, useNavigate} from "react-router-dom";

import {MainLayout} from "./layouts/MainLayout";
import {QueryClient,QueryClientProvider} from "react-query";

const queryClient = new QueryClient()
export const  App = () => {
  if(!localStorage.getItem("user")){
      return <Navigate to={"/users/login"}/>;
  }


  return (
      <QueryClientProvider client={queryClient}>
        <div className="main">
          <MainLayout>
              <Outlet/>
          </MainLayout>
        </div>
      </QueryClientProvider>
  );
}

export default App;
