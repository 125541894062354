import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import { PButton } from "../../components/controlls/PButton";
import { BasicApiMap } from "../../components/controlls/Maps/BasicApiMap";
import { GeneralOpportunityForm } from "../../components/management/opportunity/GeneralOpportunityForm";
import { OpportunityView } from "./OpportunityView";
import { useOpportunityService } from "../../services/useOpportunityService";
import { OpportunityProperties } from "./OpportunityProperties";
import { OpportunityVendors } from "./OpportunityVendors";
import { OpportunityNotes } from "./OpportunityNotes";
import { useOpportunityVendorService } from "../../services/useOpportunityVendorService";
import { useOpportunityPropertiesService } from "../../services/useOpportunityPropertiesService";
import { ViewHeader } from "../../components/controlls/DetailTemplates/Headers";
import { TabSelector } from "../../components/controlls/TabSelector";

const tabs = [
  { name: "Details", key: "details", href: "#", current: true },
  { name: "Maps", href: "#", current: false },
  { name: "Properties", href: "#", current: false },
  { name: "Work Items", href: "#", current: false },
  { name: "Notes", href: "#", current: false },
];

export const OpportunityDetails = () => {
  let { opportunityId } = useParams();
  const [data, setData] = useState({ lat: 0, lng: 0 });
  const [editMode, setEditMode] = useState(false);
  const { save, getOne } = useOpportunityService({});
  const { data: vendors } = useOpportunityVendorService({
    params: { opportunity_id: opportunityId },
  });
  const { data: properties } = useOpportunityPropertiesService({
    params: { opportunity_id: opportunityId },
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    getRecord().then((res) => {});
  }, []);
  const getRecord = async () => {
    let res = await getOne(opportunityId);
    setData(res);
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="md:flex md:items-center md:justify-between py-3">
        <ViewHeader title={`Opportunity: ${data.title}`} />
      </div>
      <TabSelector
        items={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      ></TabSelector>

      {activeIndex === 0 && (
        <>
          <OpportunityView
            data={data}
            opportunityId={opportunityId}
            setData={setData}
          />
        </>
      )}
      {activeIndex === 1 && (
        <>
          <div className={" panel"}>
            <BasicApiMap
              locationItems={[
                ...vendors.map((i) => i.vendor),
                ...properties.map((i) => i.property),
              ]}
            />
          </div>
        </>
      )}
      {activeIndex === 2 && (
        <>
          {" "}
          <OpportunityProperties
            accountId={data.account_id}
          ></OpportunityProperties>
        </>
      )}
      {activeIndex === 3 && (
        <>
          <OpportunityVendors></OpportunityVendors>
        </>
      )}
      {activeIndex === 4 && (
        <>
          <OpportunityNotes opportunityId={data.id}></OpportunityNotes>
        </>
      )}
    </div>
  );
};
