import {useEffect, useState} from "react";
import {useVendorCategoryService} from "../services/useVendorCategoryService";
import {Tree} from "primereact/tree";

export const CategorySelection = ({selectedKeys, setSelectedKeys}) =>{
    const [nodes, setNodes] = useState([]);
    const {data:categories} = useVendorCategoryService({params:{}});

    const getChildren = (parent)=>{
        let children = categories.filter(i=>i.parent_vendor_category_id === parent.id);
        children.forEach((child)=>{
            child.children = getChildren(child);
        });
        return children.map((child)=>{
            return {
                "key": child.id,
                "label": child.name,
                "data": child.name,
                leaf: !!child.children,
                children: child.children
            }
        });
    }
    useEffect(() => {


        let parents = categories.filter(i=>i.parent_vendor_category_id === 0);
        let nodes = parents.map((category, index)=>{
            return {
                "id":category.id,
                "key": category.id,
                "label": category.name,
                "data": category.name,
                leaf: false.valueOf(),
                children: getChildren(category)
            }
        });

        setNodes(nodes || []);
    }, [categories]);

    return <>
        <div>
            <br/>
            <Tree value={nodes}
                  selectionMode="checkbox"
                  selectionKeys={selectedKeys}
                  propagateSelectionDown={false}
                  onSelectionChange={e => {
                      setSelectedKeys(e.value)
                  }} />
        </div>
    </>
}