import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../../components/controlls/PButton";

export const ProficiencyGroupAdd = ({save, vendor_category_id}) => {
    const [data, setData] = useState({});
    const [show, setShow] = useState(false);

    const onSave = async () =>{
        await save(data);
        setData({});
        setShow(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value,vendor_category_id:vendor_category_id});
    }
    return <>
        <button className={"btn btn--primary"} onClick={()=>setShow(true)}>Add</button>
        <Dialog header="Add Skill Group" style={{ width: '50vw' }}  visible={show} onHide={() => setShow(false)}>
            <div className="form--group">
                <label htmlFor="">Name</label>
                <input name={"name"} onChange={onChange} value={data["name"]} type="text" className="form--input"/>
            </div>
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </>;
}