import {useState} from "react";
import {usePropertyNoteService} from "../../services/usePropertyNoteService";
import {JobTextEditor} from "../../components/controlls/JobTextEditor";
import moment from "moment";
import {useOpportunityNotesService} from "../../services/useOpportunityNotesService";

export const OpportunityNotes = ({opportunityId}) =>{
    const [message, setMessage] = useState('');

    const{data:propertyNotes, loading, deleteData, save} = useOpportunityNotesService({opportunity_id:opportunityId});

    const onSave = async () =>{
        await save({note:message, opportunity_id:opportunityId});
        setMessage('');
    }

    return <div className={"comments-box"}>
        <h3 className={"comments-box--header"}>
            Comments
        </h3>
        <div>
            <JobTextEditor message={message} setMessage={setMessage}></JobTextEditor>
        </div>
        <button onClick={onSave} className={"btn btn--primary"}>Save</button>
        <div>
            {propertyNotes.map((note, index)=>{
                return <div >
                    <div className={"feed-box--feed-item--message-item"}>
                        <div className={"feed-box--feed-item--from-name"}>
                            <div className={"feed-box--feed-item--username"}></div>
                            <div className={"feed-box--feed-item--message-time"}>{moment(note.created_at).format("hh:mm A")}</div>
                        </div>
                        <div className={"feed-box--feed-item--from-message"}  dangerouslySetInnerHTML={{__html: note.note}}>
                        </div>
                    </div>

                </div>
            })}
        </div>
    </div>
}