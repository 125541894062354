import {useEffect, useState} from "react";
import {useVendorVendorCategoryService} from "../../services/useVendorVendorCategoryService";
import {VendorCategoriesEdit} from "./VendorCategoriesEdit";
import {DisplayChildren} from "./DisplayChildren";

export const VendorCategoriesDisplay = ({vendor}) =>{
    const {createList,deleteByVendor,data: selected} = useVendorVendorCategoryService({params:{vendor_id:(vendor.id)}});
    const [edit, setEdit] = useState(false);
    return <>
        <div>
            <h5>Categories</h5>
            {!edit && <>
                <button className={"btn btn--primary"} onClick={()=>setEdit(true)}>Edit</button>
                <VendorCategoriesView vendor={vendor} selected={selected} ></VendorCategoriesView>
            </>}

            {edit && <>
                <button className={"btn btn--primary"} onClick={()=>setEdit(false)}>Cancel</button>
                <VendorCategoriesEdit vendor={vendor} deleteList={deleteByVendor} selected={selected} save={async (req) =>{
                    await createList(req);
                    setEdit(false);
                }}/>
            </> }
        </div>

    </>
}
function VendorCategoriesView({vendor,selected}) {
    let parents = selected.filter(i=>i.vendor_category.parent_vendor_category_id === 0);
    const getChildren = (parent)=>{
        let children = selected.filter(i=>i.vendor_category.parent_vendor_category_id === parent.vendor_category.id);
        children.forEach((child)=>{
            child.children = getChildren(child);
        });
        return children;
    }
    parents.forEach((parent)=>{
        parent.children = getChildren(parent);
    })
    return <>
        <DisplayChildren children={parents} level={0}></DisplayChildren>
    </>;
}

