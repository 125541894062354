import {useLaborTypesService} from "../../../services/useLaborTypesService";
import React, {useEffect, useState} from "react";

export const GeneralContactForm = ({onChange, data}) =>{


    return <>
        <div className="form">
            <div className="form--group">
                <label htmlFor="">First Name</label>
                <input name={"first_name"} onChange={onChange} value={data["first_name"]} type="text" className="form--input"/>
            </div>

            <div className="form--group">
                <label htmlFor="">Last Name</label>
                <input name={"last_name"} onChange={onChange} value={data["last_name"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Email</label>
                <input name={"email"} onChange={onChange} value={data["email"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Phone</label>
                <input name={"phone"} onChange={onChange} value={data["phone"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Secondary Phone</label>
                <input name={"phone_secondary"} onChange={onChange} value={data["phone_secondary"]} type="text" className="form--input"/>
            </div>
        </div>
    </>
}