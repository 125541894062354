const Constants = {
    api: {
        basePath: '/api/',
        clientId: 'Cipher Systems',
        clientSecret: 'v9IqX8SZm1q0uMBaQXEPEwZ4fFbHXqZy',
        options: {
            "pagingInfo": {
                "pageSize": 20,
                "pageNumber": 0
            }
        },
        auth: {
            get: '/auth/token',
            getExternal: '/auth/user_external_token',
            changeTenant: '/auth/change_tenant',
            tenants: '/auth/user_tenant_list'
        }
    }
};

export default Constants
