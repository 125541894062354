import {useBasicServices} from "../useBasicServices";
import {authorizationService} from "../authorizationService";
import axios from "axios";
import {settings} from "../settings";

export const useProficiencyGroupService = ({params={},context}) =>{
    let serviceBase = useBasicServices({service_type:'proficiency_groups', params:params,context:context});
    const {config} = authorizationService();
    serviceBase.groupsByVendor = async (id) =>{
        const {data:{data:group}} = await axios.get(`${settings.url}/api/proficiency_groups/groups_by_vendor/${id}`,config());
        return group;
    }
    return serviceBase;


}