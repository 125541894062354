import { MaterialTypesList } from "../components/Lists/MaterialTypesList";
import { PropertiesList } from "../components/Lists/PropertiesList";

export const Properties = () => {
  return (
    <div>
      <PropertiesList />
    </div>
  );
};
