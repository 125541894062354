import {useContactService} from "../../services/useContactService";
import {useListHelper} from "../../hooks/useListHelper";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {Link} from "react-router-dom";
import {basicParams} from "../../common/listUtils";
import {PButton} from "../controlls/PButton";
import {AddContact} from "../management/contact/AddContact";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React from "react";
import {useAccountContactService} from "../../services/useAccountContactService";
import {useVendorContactService} from "../../services/useVendorContactService";

export const VendorContactList = () =>{
    const{data:contacts, loading, deleteData, save, onChange,pagination} = useVendorContactService({});
    const {lazyParams,renderHeader,actionBodyTemplate,show,setShow, currentRow} = useListHelper({title:"Contacts",
        onChange,
        pagination,
        globalFields:['first_name','last_name','email','phone','phone_secondary'],
        filters:{
            'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'first_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'last_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'email': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'phone': { value: null, matchMode: FilterMatchMode.CONTAINS },
            'secondary_phone':{ value: null, matchMode: FilterMatchMode.CONTAINS },
            'status': { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
        ,onDelete:async (row)=>{
            await deleteData(row.id);
        }
    });
    const detailsLinkTemplate = (rowData) =>{
        return <Link to={`/vendor_contact/${rowData.id}`} >{rowData.last_name}</Link>
    }
    const vendorDetailsLinkTemplate = (rowData) =>{
        return <Link to={`/vendors/${rowData.vendor_id}`} >{rowData.vendor.name}</Link>
    }
    const dtParams = {
        ...basicParams,
        ...lazyParams,
        value:contacts,
        header:renderHeader(),
        loading:loading,
        emptyMessage:"No contacts found."};

    const columnExp = [
        {field:"vendor.name",header:"Vendor", sortable:true, filter:true, filterPlaceholder:"Search by Last Name", style:{ minWidth: '14rem' }, body:vendorDetailsLinkTemplate },
        {field:"last_name",header:"Last Name", sortable:true, filter:true, filterPlaceholder:"Search by Last Name", style:{ minWidth: '14rem' }, body:detailsLinkTemplate },
        {field:"first_name",header:"First Name", sortable:true, filter:true, filterPlaceholder:"Search by First Name", style:{ minWidth: '14rem' }},
        {field:"email", header:"Email", sortable:true,filter:true,  filterPlaceholder:"Search by Email", filterField:"email", style:{ minWidth: '14rem'}},
        {field: "phone", header:"Phone", sortable:true,filter:true,  sortField:"phone", filterField:"phone", filterMenuStyle:{ width: '14rem' }, style:{ minWidth: '14rem' }},
        {field: "phone_secondary", header:"Phone Secondary", sortable:true,filter:true,  sortField:"phone_secondary", filterField:"phone_secondary", filterMenuStyle:{ width: '14rem' }, style:{ minWidth: '14rem' }}
    ]
    return (
        <div className="datatable-doc-demo">
            <div className={"data-action-row"}>
                <PButton click={()=>setShow(true)}  text={"Add"} />
                <AddContact save={save} setShowModal={setShow} showModal={show} currentRow={currentRow} > </AddContact>
            </div>
            <div className="card">
                <DataTable   {...dtParams}>
                    {columnExp.map((item, index)=>{
                        return <Column key={"column-val-" + index} {...item} />
                    })}
                    <Column headerStyle={{  textAlign: 'center' }} filter={false} bodyStyle={{ minWidth: '11rem',textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
    );
}