import { useState } from "react";
import { usePropertyNoteService } from "../../services/usePropertyNoteService";
import { JobTextEditor } from "../../components/controlls/JobTextEditor";
import moment from "moment";
import { useAccountNotesService } from "../../services/useAccountNotesService";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { authorizationService } from "../../services/authorizationService";
import * as PropTypes from "prop-types";
import {
  MessageDisplay,
  MessageInput,
} from "../../components/controlls/MessagesComponents";

export const AccountNotes = ({ accountId }) => {
  const [message, setMessage] = useState("");
  const { data: notes, save } = useAccountNotesService({
    params: { account_id: accountId },
  });
  const { userName } = authorizationService();
  const onSave = async () => {
    await save({ note: message, account_id: accountId, name: userName() });
    setMessage("");
  };
  return (
    <>
      <section aria-labelledby="notes-title">
        <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-2 sm:px-6">
              <ul role="list" className="space-y-8">
                {notes.map((comment) => (
                  <MessageDisplay key={comment.id} comment={comment} />
                ))}
              </ul>
            </div>
          </div>
          <MessageInput
            message={message}
            setMessage={setMessage}
            onSave={onSave}
          />
        </div>
      </section>
    </>
  );
};
