import React from "react";
import ReactDOM from "react-dom/client";

import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "./pages/assets/vendor/nucleo/css/nucleo.css";

import "primereact/resources/primereact.min.css"; //core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme

import "primeicons/primeicons.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "./pages/assets/scss/argon-dashboard-pro-react.scss";

import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/application.sass.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Users } from "./pages/admin/Users";
import { SupplierTypes } from "./pages/admin/SupplierTypes";
import { MaterialTypes } from "./pages/admin/MaterialTypes";
import { EquipmentTypes } from "./pages/admin/EquipmentTypes";
import { LaborTypes } from "./pages/admin/LaborTypes";
import { Dashboard } from "./pages/Dashboard";
import { Opportunities } from "./pages/Opportunities";
import { Jobs } from "./pages/Jobs";
import { MapSearch } from "./pages/maps/MapSearch";
import { Accounts } from "./pages/Accounts";
import { Properties } from "./pages/Properties";
import { Contacts } from "./pages/Contacts";

import { MessageInterface } from "./pages/messaging/MessageInterface";

import { AccountFinalize } from "./pages/users/AccountFinalize";
import { UnauthLayout } from "./layouts/UnauthLayout";
import { Login } from "./pages/users/Login";
import { PropertyDetails } from "./pages/properties/PropertyDetails";

import { JobDetails } from "./pages/jobs/JobDetails";
import { PropertyTypes } from "./pages/admin/PropertyTypes";
import { MainCalendar } from "./pages/calendar/MainCalendar";
import { AccountDetails } from "./pages/accounts/AccountDetails";
import { OpportunityDetails } from "./pages/opportunities/OpportunityDetails";
import { Vendor } from "./pages/Vendor";
import { VendorDetails } from "./pages/vendors/VendorDetails";
import { ProficiencyManager } from "./pages/proficiencies/ProficiencyManager";
import { AccountContacts } from "./pages/AccountContacts";
import { VendorContacts } from "./pages/VendorContacts";
import { DataManagement } from "./pages/system/DataManagement";
import { VendorContactDetails } from "./pages/vendor_contacts/VendorContactDetails";
import { AccountContactDetails } from "./pages/account_contacts/AccountContactDetails";
import { ContentManager } from "./pages/admin/ContentManager";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "opportunities", element: <Opportunities /> },
      { path: "opportunities/:opportunityId", element: <OpportunityDetails /> },
      { path: "messaging", element: <MessageInterface /> },
      { path: "jobs", element: <Jobs /> },
      { path: "calendar", element: <MainCalendar /> },
      { path: "jobs/:jobId", element: <JobDetails /> },
      { path: "maps", element: <MapSearch /> },
      { path: "accounts", element: <Accounts /> },
      { path: "vendors", element: <Vendor /> },
      { path: "vendors/:vendorId", element: <VendorDetails /> },
      { path: "accounts/:accountId", element: <AccountDetails /> },
      { path: "properties", element: <Properties /> },
      { path: "property/:propertyId", element: <PropertyDetails /> },
      { path: "contacts", element: <Contacts /> },
      { path: "account_contacts", element: <AccountContacts /> },
      { path: "vendor_contacts", element: <VendorContacts /> },
      {
        path: "account_contact/:accountContactId",
        element: <AccountContactDetails />,
      },
      {
        path: "vendor_contact/:vendorContactId",
        element: <VendorContactDetails />,
      },
      { path: "admin/users", element: <Users /> },
      { path: "admin/import_manager", element: <DataManagement /> },
      { path: "admin/content_manager", element: <ContentManager /> },
      { path: "admin/proficiency_manager", element: <ProficiencyManager /> },
      { path: "admin/supplier_types", element: <SupplierTypes /> },
      { path: "admin/material_types", element: <MaterialTypes /> },
      { path: "admin/equipment_types", element: <EquipmentTypes /> },
      { path: "admin/labor_types", element: <LaborTypes /> },
      { path: "admin/property_types", element: <PropertyTypes /> },
    ],
  },
  {
    path: "/users",
    element: <UnauthLayout />,
    children: [
      {
        path: ":token",
        element: <AccountFinalize />,
      },
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
