import { Link, useParams } from "react-router-dom";
import { useOpportunityPropertiesService } from "../../services/useOpportunityPropertiesService";
import { useListHelper } from "../../hooks/useListHelper";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { basicParams } from "../../common/listUtils";
import { PButton } from "../../components/controlls/PButton";
import { OpportunityPropertyAdd } from "../opportunities/OpportunityPropertyAdd";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { AddOpportunity } from "../../components/management/opportunity/AddOpportunity";

export const PropertyOpportunity = ({ propertyId }) => {
  let { opportunityId } = useParams();
  const {
    data: opportunities,
    loading,
    deleteData,
    save,
    onChange,
    pagination,
  } = useOpportunityPropertiesService({ params: { property_id: propertyId } });
  const {
    lazyParams,
    renderHeader,
    actionBodyTemplate,
    filters,
    show,
    setShow,
    currentRow,
    onGlobalFilterChange,
  } = useListHelper({
    onChange,
    pagination,
    title: "Contacts",
    filters: {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      address1: { value: null, matchMode: FilterMatchMode.CONTAINS },
      city: { value: null, matchMode: FilterMatchMode.CONTAINS },
      cost: { value: null, matchMode: FilterMatchMode.CONTAINS },
      description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    },
    onDelete: async (row) => {
      await deleteData(row.id);
    },
  });

  const columnExp = [
    {
      field: "opportunity.title",
      header: "Name",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by Name",
      style: { minWidth: "14rem" },
    },
    {
      field: "property.address1",
      header: "Address",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search Address",
      style: { minWidth: "14rem" },
    },
    {
      field: "property.city",
      header: "City",
      sortable: true,
      filter: true,
      filterPlaceholder: "Search by City",
      filterField: "email",
      style: { minWidth: "14rem" },
    },
    {
      field: "cost",
      header: "Cost",
      sortable: true,
      filter: true,
      sortField: "state",
      filterField: "state",
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
    {
      field: "description",
      header: "Description",
      sortable: true,
      filter: true,
      sortField: "state",
      filterField: "state",
      filterMenuStyle: { width: "14rem" },
      style: { minWidth: "14rem" },
    },
  ];
  return (
    <>
      <>
        <div className="">
          <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div className="ml-4 mt-2">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Opportunities
                </h3>
              </div>
              <div className="ml-4 mt-2 flex-shrink-0">
                <button
                  onClick={() => setShow(true)}
                  type="button"
                  className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create new opportunity
                </button>
              </div>
            </div>
          </div>

          <div className="py-4 bg-white  flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block h-full w-full border-0 py-0 pl-5 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder="Search..."
                type="search"
                name="search"
                onChange={onGlobalFilterChange}
              />
            </form>
          </div>

          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {opportunities.map((opportunity) => (
                <li key={opportunity.id}>
                  <a
                    href={`/opportunities/${opportunity.id}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="flex min-w-0 flex-1 items-center">
                        <div className="flex-shrink-0">
                          {opportunity.imageUrl && (
                            <img
                              className="h-12 w-12 rounded-full"
                              src={opportunity.imageUrl}
                              alt=""
                            />
                          )}
                          {!opportunity.imageUrl && (
                            <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
                              <svg
                                className="h-full w-full text-gray-300"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                              </svg>
                            </span>
                          )}
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {opportunity.opportunity.title}
                            </p>
                          </div>
                        </div>
                        <div>
                          <ChevronRightIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="datatable-doc-demo">
          <div className={"data-action-row"}>
            <AddOpportunity
              save={save}
              setShowModal={setShow}
              showModal={show}
              currentRow={currentRow}
            >
              {" "}
            </AddOpportunity>
          </div>
        </div>
      </>
    </>
  );
};
