import React, {useState} from 'react';

import {ObjContainer} from '../../../services/object_container';
import useFieldUpdater from '../../../services/hooks/useFieldUpdater';
import {useSearchClear} from '../../../services/hooks/useSearch';
import {ImportColumns} from './ImportColumns';
import {ImportGeneral} from './ImportGeneral';
import {DataView} from './DataView';
import TabbedView from "../../../common/Forms/TabbedView/TabbedView";
import {ImportService} from "../../../services/import_service";
import {TabPanel, TabView} from "primereact/tabview";
import {QueryBuilder} from "./QueryBuilder";

const hookContext = {
    entity: 'import_files'
};
export const ImportManager= ()=>{
    const [definition, setDefinition ] = useState({import_table_name:'', lines_to_skip:0});
    const [message, setMessage] = useState('');
    const [cols, setCols] = useState([]);
    const [uploadDisabled, setUploadDisabled] = useState(false);
    const [schemaDisabled, setSchemaDisabled] = useState(true);
    const [csvRows, setCsvRows] = useState([]);
    const [file, setFile] = useState('');
    const [ext, setExt] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const {update} = useFieldUpdater({state:definition, setState:setDefinition});
    const { clear } = useSearchClear({
        ...hookContext
    });
    const handleFiles = (e)=>{
        let newFile = e.target.files[0];
        let lastDot = newFile.name.lastIndexOf('.');
        let cExt = newFile.name.substring(lastDot + 1);
        setExt(cExt);
        setFile(newFile);
        showSchema(newFile,cExt);
        setSchemaDisabled(false);
    };
    const upload = async ()=>{
        setUploadDisabled(true);
        let importService = new ImportService();
        await importService.import_file([file],definition,definition);
        await clear();
        setMessage('Upload Finished Successfully');
        setTimeout(()=>{
            resetState();
        },3000)

    };
    const resetState = () =>{
        setMessage("");
        setCsvRows([]);
        setCols([]);
        setFile("");
        setExt("");
        setUploadDisabled(false);
        setSchemaDisabled(true);
        setDefinition({import_table_name:'', lines_to_skip:0});
    }
    const showSchema = (newFile,cExt)=>{
        if(cExt === 'csv'){
            let reader = new FileReader();
            reader.onload = (e)=> {
                let data = reader.result.split('\n');
                let headers = data[+definition.lines_to_skip].split(',');
                setCsvRows(data.slice(1, 11).map((item)=>{
                    let itemObj = {};
                    let varItems = item.split(',');
                    headers.map((head, headIndex)=>{
                        itemObj[head] =  varItems[headIndex];
                    });
                    return itemObj;
                }));
                setCols(headers);
                let updated  = update('file_name',newFile.name);
                updated.fields =  headers;
                setDefinition(updated);
            };
            reader.readAsText(newFile);
        }
    };

    return (<div>
        { message && <><br/>
            <div className="alert alert-success" role="alert">
                {message}
            </div></>}

        <div>
            <ImportGeneral handleFiles={handleFiles} uploadDisabled={uploadDisabled} update={update} upload={upload} definition={definition} />
            <button className={'btn btn-primary'} disabled={uploadDisabled} onClick={upload}>Upload</button>&nbsp;
            <button className={'btn btn-primary'} onClick={resetState}>Reset</button>

            <TabView className={"full-tab-view"}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Preview">
                    <DataView cols={cols} rows={csvRows} />
                </TabPanel>
                <TabPanel header="Columns">
                    <ImportColumns cols={cols} />
                </TabPanel>
            </TabView>
        </div>
    </div>);
};

