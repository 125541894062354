import React, { useEffect, useState } from "react";
import { useLaborTypesService } from "../../../services/useLaborTypesService";
import { Dialog } from "primereact/dialog";
import { PButton } from "../../controlls/PButton";
import * as PropTypes from "prop-types";
import { useJobService } from "../../../services/useJobService";
import { GeneralJobForm } from "./GeneralJobForm";

export const AddJob = ({
  save,
  setShowModal,
  showModal = false,
  currentRow,
  updated,
}) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(currentRow);
  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  useEffect(() => {
    setData(currentRow);
  }, [currentRow]);
  const onSave = async () => {
    await save({ ...data });
    setData({});
    setShowModal(false);
    await updated();
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      {showModal && (
        <div className={"absolute top-0 left-0 w-full h-100 bg-white"}>
          <div className={"flex"}>
            <h2>Add Job</h2>
          </div>
          <div>
            <GeneralJobForm data={data} onChange={onChange} />
          </div>

          <div>
            <PButton
              text={"cancel"}
              click={async () => {
                setShowModal(false);
              }}
            />
            <PButton
              text={"save"}
              click={async () => {
                await onSave();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

AddJob.propTypes = {
  setShowModal: PropTypes.func,
  currentRow: PropTypes.shape({}),
  showModal: PropTypes.bool,
  updated: PropTypes.func,
};
