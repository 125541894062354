export const useIsValid = (validations) =>{
    return {
       isValidFunc: (targetValue,obj)=>{
            return validations.reduce((invalid, item)=>{
                if(!item.validation.valid(targetValue, item, obj))
                    invalid.push(item.message);
                return invalid;
            },[]);
        }
    }
}