import { useJobTaskService } from "../../services/useJobTaskService";
import { useParams } from "react-router-dom";
import { AddJobTask } from "../../components/management/job/AddJobTask";
import { PButton } from "../../components/controlls/PButton";
import React, { useState } from "react";
import * as moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
export const JobTask = () => {
  let { jobId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [dataItem, setDataItem] = useState({
    title: "",
    due_date: "",
    assigned_to_id: -1,
  });
  const { data, save, addNextPage, hasMore } = useJobTaskService({
    job_id: jobId,
  });
  const saveJob = async () => {
    await save({ ...dataItem, job_id: jobId });
    setDataItem({ title: "", due_date: "", assigned_to_id: -1 });
  };
  return (
    <div>
      <PButton
        text={"Add"}
        click={() => {
          setShowModal(true);
        }}
      />
      <AddJobTask
        currentRow={dataItem}
        update={setDataItem}
        save={saveJob}
        showModal={showModal}
        setShowModal={setShowModal}
      ></AddJobTask>
      <div className={"task-list"}>
        <InfiniteScroll
          dataLength={data.length}
          next={addNextPage}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Showing All Results</b>
            </p>
          }
        >
          {data.map((item, index) => {
            return (
              <div key={`task_item_${index}`} className={"task-item"}>
                <div className={"task-item--type-1"}></div>
                <div className={"task-item--def"}>
                  <div className={"task-item--content"}>{item.title}</div>
                  <div className={"task-item--header"}>
                    <div className={"task-item--header--name"}>
                      {item.assigned_to.first_name} {item.assigned_to.last_name}
                    </div>
                    <div className={"task-item--header--date"}>
                      {moment(item.due_date).format("M/D/yyyy")}
                    </div>
                  </div>
                </div>
                {item.status === 1 && (
                  <div className={"task-item--check"}>
                    <button
                      onClick={async () => {
                        await save({ ...item, status: 0 });
                      }}
                    >
                      <i className={"fas fa-check"}></i>
                    </button>
                  </div>
                )}
                {!item.status && (
                  <div className={"task-item--check-not-complete"}>
                    <button
                      onClick={async () => {
                        await save({ ...item, status: 1 });
                      }}
                    >
                      &nbsp;
                    </button>
                  </div>
                )}
              </div>
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};
