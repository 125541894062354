import React, { useEffect, useState } from "react";
import { AddressForm } from "../../controlls/Address/AddressForm";
import { useDataAttributeService } from "../../../services/useDataAttributeService";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
export const GeneralAccountForm = ({ onChange, data }) => {
  const { data: attributes } = useDataAttributeService({
    params: { attribute_type: "customer" },
  });
  useEffect(() => {}, []);
  const lOnChange = (field, value) => {
    let tmp = {
      ...data,
      attribute_data: { ...data.attribute_data, [field.attribute_name]: value },
    };
    onChange({ target: { name: "attribute_data", value: tmp.attribute_data } });
  };
  return (
    <>
      <div className="space-y-5 divide-y divide-gray-900/10">
        <div className="">
          <div className="bg-white form">
            <div className="sm:divide-y sm:divide-gray-200">
              <div className="py-3  sm:px-3 sm:py-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-400"
                >
                  Account Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    value={data["name"]}
                    onChange={onChange}
                    id="name"
                    className="block w-full rounded-md  py-1.5 text-gray-700 shadow-sm ring-1 ring-inset border-gray-200 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Big Lots"
                  />
                </div>
              </div>

              <div className="py-3  sm:px-3 sm:py-3">
                <label
                  htmlFor="website"
                  className="block text-sm font-medium leading-6 text-gray-400"
                >
                  Website
                </label>
                <div className="mt-2">
                  <input
                    name={"website"}
                    id={"website"}
                    onChange={onChange}
                    value={data["website"]}
                    type="text"
                    className="block w-full rounded-md  py-1.5 text-gray-700 shadow-sm ring-1 ring-inset border-gray-200 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="www.example.com"
                  />
                </div>
              </div>

              <div className="py-3  sm:px-3 sm:py-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-400"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    name={"email"}
                    id={"email"}
                    onChange={onChange}
                    value={data["email"]}
                    type="text"
                    className="block w-full rounded-md  py-1.5 text-gray-700 shadow-sm ring-1 ring-inset border-gray-200 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="test@test.com"
                  />
                </div>
              </div>
              <div className="py-3  sm:px-3 sm:py-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-400"
                >
                  Phone
                </label>
                <div className="mt-2">
                  <input
                    name={"phone"}
                    onChange={onChange}
                    value={data["phone"]}
                    type="text"
                    className="block w-full rounded-md  py-1.5 text-gray-700 shadow-sm ring-1 ring-inset border-gray-200 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="(234)123-1234"
                  />
                </div>
              </div>
            </div>

            {attributes.map((attr, index) => {
              return (
                <>
                  <div
                    className="form--group"
                    key={"custom_attribute_" + index}
                  >
                    <label>{attr.attribute_display}</label>
                    <input
                      className={"form--input"}
                      type="text"
                      value={
                        (data.attribute_data || {})[attr.attribute_name] || ""
                      }
                      onChange={({ target: { value } }) => {
                        lOnChange(attr, value);
                      }}
                    />
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
