import React, {useEffect, useState} from "react";
import {Dialog} from "primereact/dialog";
import {GeneralContactForm} from "../../components/management/contact/GeneralContactForm";
import {PButton} from "../../components/controlls/PButton";

export const AddVendorContact = ({vendorId,save,setShowModal, showModal=false,currentRow, updated}) =>{
    const [show, setShow] = useState(false);
    const [data, setData] = useState(currentRow || {contact:{}});

    useEffect(()=>{
        setShow(showModal)
    },[showModal])
    useEffect(()=>{
        setData(currentRow);
    },[currentRow])
    const onSave = async () =>{
        await save({...data, vendor_id:vendorId});
        setData({contact:{}});
        setShowModal(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data, [name]:value});
    }
    return <div>
        <Dialog header="Add Vendor Contact" style={{ width: '50vw' }}  visible={show} onHide={() => setShowModal(false)}>
            <GeneralContactForm data={data || {}} onChange={onChange} />
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}