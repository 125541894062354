import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useJobService } from "../../services/useJobService";
import { GeneralJobView } from "../../components/management/job/GeneralJobView";
import { MessageInterface } from "../messaging/MessageInterface";
import { JobTask } from "./JobTask";
import { BasicApiMap } from "../../components/controlls/Maps/BasicApiMap";
import { NearbyMap } from "../../components/controlls/Maps/NearbyMap";
import { JobVendors } from "./JobVendors";
import { TabSelector } from "../../components/controlls/TabSelector";

import {
  EditRow,
  ViewHeader,
} from "../../components/controlls/DetailTemplates/Headers";
import { JobTeam } from "./JobTeam";
const tabs = [
  { name: "Details", key: "details", href: "#", current: true },
  { name: "Team", href: "#", current: false },
  { name: "Maps", href: "#", current: false },
  { name: "Tasks", href: "#", current: false },
  { name: "Notes", href: "#", current: false },
  { name: "Vendors", href: "#", current: false },
];

export const JobDetails = () => {
  const [data, setData] = useState({});
  const [editMode, setEditMode] = useState(false);
  let { jobId } = useParams();
  const { save, getOne } = useJobService({});
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
    getOne(jobId).then((res) => {
      setData(res);
    });
  }, []);
  const onSave = async () => {
    await save({ ...data });
    setEditMode(false);
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div style={{ height: "80vh" }}>
      <div className="md:flex md:items-center md:justify-between py-3">
        <ViewHeader title={`Job: ${data.name}`} />
      </div>
      <TabSelector
        items={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      ></TabSelector>

      {activeIndex === 0 && (
        <>
          <GeneralJobView data={data} setData={setData} onSave={onSave} />
        </>
      )}
      {activeIndex === 1 && (
        <>
          <JobTeam jobId={jobId} />
        </>
      )}
      {activeIndex === 2 && (
        <>
          <div>
            <NearbyMap reference={data.property} />
            <BasicApiMap locationItems={[data.property || {}]} />
          </div>
        </>
      )}
      {activeIndex === 3 && (
        <>
          <div className={"panel"}>
            <h2>Tasks</h2>
            <JobTask></JobTask>
          </div>
        </>
      )}
      {activeIndex === 4 && (
        <>
          <MessageInterface context={`/job/${data.id}`}></MessageInterface>
        </>
      )}
      {activeIndex === 5 && (
        <>
          <JobVendors jobId={jobId} />
        </>
      )}
    </div>
  );
};
