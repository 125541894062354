import {useProficiencyRatingService} from "../../../services/proficiencies/useProficiencyRatingService";
import React from "react";
import {ProficiencyRatingsAdd} from "./ProficiencyRatingAdd";

export const ProficiencyRating = ({group}) =>{
    const {save:saveRatings, data:ratings,deleteData} = useProficiencyRatingService({params:{proficiency_group_id:group.id}});
    return <><h3>Skill Levels <ProficiencyRatingsAdd save={saveRatings}  proficiency_group_id={group.id}></ProficiencyRatingsAdd></h3>
        <table className={"table"}>
            <thead>
            <tr>
                <th>Display</th>
                <th>Order</th>
                <th>Value</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {ratings.map((rating, index)=>{
                return <tr key={"category-" + rating.id}>
                    <td>{rating.name}</td>
                    <td>{rating.order}</td>
                    <td>{rating.numeric_value}</td>
                    <td><button className={"link-button"} onClick={async ()=>{await deleteData(rating.id) }}><i className={"fa-solid fa-trash"}></i></button></td>
                </tr>
            })}
            </tbody>
        </table></>;
}
