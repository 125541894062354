import {UserService} from "../../services/UserService";
import {useEffect, useState} from "react";
import {regex_validation, must_equal,required,useValidations} from "../../services/validations";
import {useNavigate, useParams} from "react-router-dom";
import {PasswordField} from "../../components/controlls/PasswordField";


export const AccountFinalize = () =>{
    const {updatePassword} = UserService();
    const [data, setData] = useState({password:'', confirm_password:''});
    const [validationState, setValidationState] = useState({});
    let { token } = useParams();
    const navigate = useNavigate();
    const update = ({target:{name, value}}) =>{
        setData({...data,...{[name]:value}});
   }

    const form = useValidations({
        'password': {
            validations: [
                {validation: required, message: "Must Select a Password"},
                {validation: regex_validation,
                    regex:"^(?=.*\\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$",
                    message: "Password must have an upper case, lower case, a number, and a special character (@$!%*#?&). "}
            ]
        },
        'confirm_password': {
            validations: [
                {validation: required, message: "Must Confirm Password"},
                {validation: must_equal, target: 'password', message: "Password and Confirmation Must Match"}
            ]
        },
    });
    const save = async () =>{
        if(!form.isValid(data))
        {
            console.log("form not valid");
            setValidationState(form.getValidState(data));
            return;
        }
        await updatePassword({...data,...{token:token}});
        navigate("/users/login")

    }
    return <div className={"login-layout panel"}>
        <div className={"login-layout--title"}>Enter a Password</div>
            <div className={"form--group"}>
                <label htmlFor="">Password</label>
                <PasswordField valid={validationState.password}
                   validations={form.def.password.validations}
                   onChange={update}
                   name={"password"}
                   value={data["password"]}
                   placeholder={"Password"} obj={data}/>
            </div>
            <div className={"form--group"}>
                <label htmlFor="">Confirm Password</label>
                <PasswordField valid={validationState.confirm_password}
                    validations={form.def.confirm_password.validations}
                    onChange={update}
                    name={"confirm_password"}
                    value={data["confirm_password"]}
                    placeholder={"confirm password"} obj={data}/>
            </div>
        <div className={"form--group"}>
            <button className={"btn btn--primary"} onClick={async ()=> await save()}>Save</button>
        </div>
    </div>
}


