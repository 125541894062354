import axios from "axios";
import { settings } from "./settings";
import { authorizationService } from "./authorizationService";
import { paramString } from "./common/urlSerializeer";
import { useBasicServices } from "./useBasicServices";

export const useJobService = ({ params = {}, context = {} }) => {
  return {
    ...useBasicServices({
      service_type: "jobs",
      params: params,
      context: context,
    }),
    ...{
      activeJobs: () => {},
    },
  };
};
