import escapeHtml from "escape-html";

export const serialize = node => {
    if(Array.isArray(node)){
        return node.map((i)=>serialize(i)).join('')
    }
    if (node.text) {
        let string = escapeHtml(node.text)
        if (node.bold) {
            string = `<strong>${string}</strong>`
        }
        if (node.italic) {
            string = `<i>${string}</i>`
        }
        if (node.underline) {
            string = `<u>${string}</u>`
        }
        return string
    }

    const children = (node.children || []).map(n => serialize(n)).join('')
    if(!children) return '';
    const style = { textAlign: node.align }
    switch (node.type) {
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`
        case 'paragraph':
            return `<p style={style} >${children}</p>`
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`
        default:
            return children
    }
}
