import {useContactService} from "../../services/useContactService";
import {useListHelper} from "../../hooks/useListHelper";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {Link} from "react-router-dom";
import {basicParams} from "../../common/listUtils";
import {PButton} from "../controlls/PButton";
import {AddContact} from "../management/contact/AddContact";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import React, {useState} from "react";
import {useAccountContactService} from "../../services/useAccountContactService";

export const AccountContactList = () =>{
    const{data:contacts, loading, deleteData, save,onChange,pagination} = useAccountContactService({});
    const {lazyParams,renderHeader,actionBodyTemplate, show,setShow, currentRow} = useListHelper(
        {
            title:"Contacts",
            pagination,
            onChange,
            globalFields:['first_name','last_name','email','phone','phone_secondary'],
            filters: {
                'global':          { value:'', matchMode: FilterMatchMode.CONTAINS},
                'account.name':    { value: '', matchMode: FilterMatchMode.CONTAINS },
                'first_name':      { value:'', matchMode: FilterMatchMode.CONTAINS },
                'last_name':       { value:'',matchMode: FilterMatchMode.CONTAINS  },
                'email':           { value:'', matchMode: FilterMatchMode.CONTAINS },
                'phone':           { value:'', matchMode: FilterMatchMode.CONTAINS },
                'phone_secondary': { value:'', matchMode: FilterMatchMode.CONTAINS },
                'status':          { value:'', matchMode: FilterMatchMode.CONTAINS}
            }
            ,onDelete:async (row)=>{
                await deleteData(row.id);
            }
    });

    const detailsLinkTemplate = (rowData) =>{
        return <Link to={`/account_contact/${rowData.id}`} >{rowData.last_name}</Link>
    }
    const accountDetailsLinkTemplate = (rowData) =>{
        return <Link to={`/accounts/${rowData.account_id}`} >{rowData.account.name}</Link>
    }
    const dtParams = {
        ...basicParams,
        ...lazyParams,
        value:contacts,
        header:renderHeader(),
        loading:loading,
        emptyMessage:"No contacts found."};
    const columnExp = [
        {field:"account.name",header:"Account", sortable:false, filter:false, filterPlaceholder:"Search by Account Name", style:{ minWidth: '14rem' }, body:accountDetailsLinkTemplate },
        {field:"last_name",header:"Last Name", sortable:true, filter:true, filterPlaceholder:"Search by Last Name", style:{ minWidth: '14rem' }, body:detailsLinkTemplate },
        {field:"first_name",header:"First Name", sortable:true, filter:true, filterPlaceholder:"Search by First Name", style:{ minWidth: '14rem' }},
        {field:"email", header:"Email", sortable:true,filter:true,  filterPlaceholder:"Search by Email", filterField:"email", style:{ minWidth: '14rem'}},
        {field: "phone", header:"Phone", sortable:true,filter:true,  sortField:"phone", filterField:"phone", filterMenuStyle:{ width: '14rem' }, style:{ minWidth: '14rem' }},
        {field: "phone_secondary", header:"Phone Secondary", sortable:true,filter:true,  sortField:"phone_secondary", filterField:"phone_secondary", filterMenuStyle:{ width: '14rem' }, style:{ minWidth: '14rem' }}

    ]
    return (
        <div className="datatable-doc-demo">
            <div className={"data-action-row"}>
                <PButton click={()=>setShow(true)}  text={"Add"} />
                <AddContact save={save} setShowModal={setShow} showModal={show} currentRow={currentRow} > </AddContact>
            </div>
            <div className="card">
                <DataTable   {...dtParams}>
                    {columnExp.map((item, index)=>{
                        return <Column key={"column-val-" + index} {...item} />
                    })}
                    <Column headerStyle={{  textAlign: 'center' }} filter={false} bodyStyle={{ minWidth: '11rem',textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
    );
}