import { useSupplierTypesService } from "../../../services/useSupplierTypesService";
import React, { useEffect, useState } from "react";
import { usePropertyTypesService } from "../../../services/usePropertyTypeService";
import { TypeAheadSearch } from "../../controlls/TypeAhead/TypeAheadSearch";
import { SelectAccount } from "../../Selectors/SelectAccount";

export const GeneralPropertyForm = ({ onChange, data }) => {
  const { get } = usePropertyTypesService();
  const [propertyTypes, setPropertyTypes] = useState([]);
  useEffect(() => {
    get().then((res) => {
      setPropertyTypes(res);
    });
  }, []);
  return (
    <>
      <div className="form">
        <div className="form--group">
          <SelectAccount
            selectedAccount={data["account"]}
            onChange={onChange}
          />
        </div>

        <div className="form--group">
          <label htmlFor="">Name</label>
          <input
            name={"name"}
            onChange={onChange}
            value={data["name"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Property Type</label>
          <select
            name={"property_type_id"}
            onChange={onChange}
            value={data["property_type_id"]}
            className="form--input"
          >
            <option value="">--Select One</option>
            {propertyTypes.map((item, index) => {
              return (
                <option key={"property_type_" + index} value={item.id}>
                  {" "}
                  {item.name}{" "}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form--group">
          <label htmlFor="">Address 1</label>
          <input
            name={"address1"}
            onChange={onChange}
            value={data["address1"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Address 2</label>
          <input
            name={"address2"}
            onChange={onChange}
            value={data["address2"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">City</label>
          <input
            name={"city"}
            onChange={onChange}
            type="text"
            value={data["city"]}
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">State</label>
          <div>
            <input
              name={"state"}
              onChange={onChange}
              value={data["state"]}
              type="text"
              className="form--input"
            />
          </div>
        </div>
        <div className="form--group">
          <label htmlFor="">Zip</label>
          <div>
            <input
              name={"zip"}
              onChange={onChange}
              value={data["zip"]}
              type="text"
              className="form--input"
            />
          </div>
        </div>
        <hr />
        <div className="form--group">
          <label htmlFor="">Constraints</label>
          <div>
            <input
              name={"constraints"}
              onChange={onChange}
              value={data["constraints"]}
              type="text"
              className="form--input"
            />
          </div>
        </div>
        <div className="form--group">
          <label htmlFor="">Property Access</label>
          <div>
            <input
              name={"property_access"}
              onChange={onChange}
              value={data["property_access"]}
              type="text"
              className="form--input"
            />
          </div>
        </div>
        <div className="form--group">
          <label htmlFor="">Open 24 Hours</label>
          <div>
            <input
              name={"open_24_hrs"}
              onChange={onChange}
              value={data["open_24_hrs"]}
              type="text"
              className="form--input"
            />
          </div>
        </div>
        <div className="form--group">
          <label htmlFor="">Total Sq Ft</label>
          <div>
            <input
              name={"total_sq_ft"}
              onChange={onChange}
              value={data["total_sq_ft"]}
              type="number"
              className="form--input"
            />
          </div>
        </div>
      </div>
    </>
  );
};
