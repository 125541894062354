export const DisplayChildren = ({children, level}) =>{
    const offset = () =>{
        let levels = [];
        for(let i = 0;i< level;i++){
            levels.push("--")
        }
        return levels.join("");
    }
    return <>
        {children.map((item, index)=>{
            return <div key={"vendor-category-" + index}>
                {offset()} {item.vendor_category.name}
                <DisplayChildren children={item.children} level={level+1}></DisplayChildren>
            </div>
        })}
    </>
}

