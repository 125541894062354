export const authorizationService = () => {
  const getToken = () => {
    return localStorage.getItem("token");
  };
  return {
    userName: () => {
      return JSON.parse(localStorage.getItem("user")).email;
    },
    userId: () => {
      return JSON.parse(localStorage.getItem("user")).id;
    },
    config: () => {
      return { headers: { Authorization: `bearer ${getToken()}` } };
    },
    header: () => {
      return { Authorization: `bearer ${getToken()}` };
    },
    token: () => {
      return getToken();
    },
  };
};
