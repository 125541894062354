import React from "react";

export const AddressView = ({data}) =>{
    return <>
        <div className="form--group">
            <label htmlFor="">Address</label>

            <div>{data["address1"]},</div>
            <div>{data["address2"]}</div>
            <div>{data["city"]} {data["state"]}, {data["zip"]}</div>
        </div>
    </>
}