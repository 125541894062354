import { JobTextEditor } from "../../components/controlls/JobTextEditor";
import { useState } from "react";
import { usePropertyContactService } from "../../services/usePropertyContactService";
import moment from "moment/moment";
import { usePropertyNoteService } from "../../services/usePropertyNoteService";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import {
  MessageDisplay,
  MessageInput,
} from "../../components/controlls/MessagesComponents";

export const PropertyNotes = ({ propertyId }) => {
  const [message, setMessage] = useState("");

  const { data: notes, save } = usePropertyNoteService({
    property_id: propertyId,
  });

  const onSave = async () => {
    await save({ note: message, property_id: propertyId });
    setMessage("");
  };

  return (
    <>
      <section aria-labelledby="notes-title">
        <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-2 sm:px-6">
              <ul role="list" className="space-y-8">
                {notes.map((comment) => (
                  <MessageDisplay key={comment.id} comment={comment} />
                ))}
              </ul>
            </div>
          </div>
          <MessageInput
            message={message}
            setMessage={setMessage}
            onSave={onSave}
          />
        </div>
      </section>
    </>
  );
};
