import React from 'react';

export const ImportGeneral = ({update,uploadDisabled,  definition,upload, handleFiles}) =>{
    return <div >
                <div className="form-group">
                    <label htmlFor="">Import XLSX / CSV </label>
                    {definition.file_name && <div>{definition.file_name} <a className={'inline-nav-link'} onClick={()=>update('file_name', undefined)}>clear</a></div>}
                    {!definition.file_name && <input className={'form-control'}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type={'file'}
                        onChange={(e) => handleFiles(e)}/>}
                </div>

                <div className="form-group">
                    <label htmlFor="">Table Name</label>
                    <input type="text" name={'import_table_name'} className={'form--input'} value={definition.import_table_name}
                        onChange={(e)=>update('import_table_name',e.target.value)}/>
                </div>

                <div className="form-group">
                    <label htmlFor="">Lines to skip</label>
                    <input type="number" name={'lines_to_skip'} className={'form--input'} value={definition.lines_to_skip}
                        onChange={(e)=>update('lines_to_skip',e.target.value)}/>
                </div>





    </div>;
};