import { useEffect, useState } from "react";
import axios from "axios";
import { settings } from "./settings";
import { authorizationService } from "./authorizationService";
import { useBasicServices } from "./useBasicServices";

export const usePropertyContactService = ({ params = {} }) => {
  return useBasicServices({
    service_type: "property_contacts",
    params: params,
  });
};
