export const INITIAL = -1;
export const VALID = 1;
export const INVALID =2;
export const required = {
    valid: (value)=>{
        return !!value;
    }
}
export const regex_validation = {
    valid: (value, validation) =>{
        let regexValid = new RegExp(validation.regex);
        console.log("match", validation.regex, regexValid.test(value));
        return regexValid.test(value);
    }
}
export const must_equal = {
    valid: (value, validation, obj)=>{
        console.log(value, validation, obj);
        return value === obj[validation.target];
    }
}
export const useValidations = (form) => {
    return {def: form, ...{
        isValid: (obj)=>{
            let valid = true;
            for(let key in form){
                valid = valid && !form[key].validations.reduce((arr, item)=>{
                    if(!item.validation.valid(obj[key],item,obj))
                        arr.push(false);
                    return arr;
                },[]).length;
            }
            return valid;
        },
        getValidState: (obj)=>{
            let validState = {};
            for(let key in form){
                form[key].validations.forEach(( item)=>{
                    validState[key] = (validState[key] &&
                        item.validation.valid(obj[key],item, obj)) ? INVALID : VALID;
                });
            }
            return validState;
        }}};
}