import React, { useEffect } from "react";
import { AddressForm } from "../../controlls/Address/AddressForm";
import Input from "react-phone-number-input/input";

export const GeneralVendorForm = ({ onChange, data }) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="form">
        <div className="form--group">
          <label htmlFor="">Vendor Name</label>
          <input
            name={"name"}
            onChange={onChange}
            value={data["name"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Website</label>
          <input
            name={"website"}
            onChange={onChange}
            value={data["website"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Email</label>
          <input
            name={"email"}
            onChange={onChange}
            value={data["email"]}
            type="text"
            className="form--input"
          />
        </div>
        <div className="form--group">
          <label htmlFor="">Phone</label>

          <input
            name={"primary_phone"}
            onChange={onChange}
            value={data["primary_phone"]}
            type="text"
            className="form--input"
          />
        </div>

        <AddressForm data={data} onChange={onChange} />
      </div>
    </>
  );
};
