import React, {useEffect, useRef, useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";
import {useVendorCategoryService} from "../../services/useVendorCategoryService";
import {CategoryManager} from "./components/CategoryManager";
import {ServiceGroups} from "./ServiceGroups";
import {useVendorCategoryAttributeService} from "../../services/useVendorCategoryAttributeService";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../components/controlls/PButton";

export const AddCategoryAttribute = ({save, vendor_category_id}) =>{
    const [data, setData] = useState({});
    const [show, setShow] = useState(false);

    const onSave = async () =>{
        await save(data);
        setData({});
        setShow(false);
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value,vendor_category_id:vendor_category_id});
    }
    return <>
        <button className={"btn btn--primary"} onClick={()=>setShow(true)}>Add</button>
        <Dialog header="Add Attribute" style={{ width: '50vw' }}  visible={show} onHide={() => setShow(false)}>
            <div className="form--group">
                <label htmlFor="">Name</label>
                <input name={"name"} onChange={onChange} value={data["name"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Display</label>
                <input name={"attribute_display"} onChange={onChange} value={data["attribute_display"]} type="text" className="form--input"/>
            </div>
            <div className="form--group">
                <label htmlFor="">Data Type</label>
                <select name={"attribute_type"} onChange={onChange} value={data["attribute_type"]} className="form--input">
                    <option value="">--Choose</option>
                    <option value="string">String</option>
                    <option value="int">Integer</option>
                    <option value="number">Number</option>
                    <option value="money">Money</option>
                    <option value="picker">Pick List</option>
                </select>
            </div>

            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </>;
}

export const AttributeManager = ({selectedKey}) =>{
    const {save, data:attributes, deleteData} = useVendorCategoryAttributeService({params:{vendor_category_id:(selectedKey || 0)},context:[selectedKey]});
    return <>
        <AddCategoryAttribute save={save} vendor_category_id={selectedKey}/>
        <table className={"table"}>
            <thead>
            <tr><th>Name</th><th>Display</th><th>Data Type</th><th></th></tr>
            </thead>
            <tbody>
            {attributes.map((attribute, index)=>{
                return <tr>
                    <td>{attribute.name}</td><td>{attribute.attribute_display}</td><td>{attribute.attribute_type}</td><td><a onClick={async ()=>{
                    await deleteData(attribute.id);
                }}><i className={"fas fa-trash"}></i></a></td>
                </tr>
            })}
            </tbody>

        </table>

    </>;
}

export const ProficiencyManager = () =>{
    const [selectedKey, setSelectedKey] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const {save:saveCategory, data:categories} = useVendorCategoryService({params:{}});


    return <div>
        <div className={"display-row"}>
            <div className={"display-column categories-panel"}>
                <CategoryManager saveCategory={saveCategory} categories={categories} selectedKey={selectedKey} setSelectedKey={setSelectedKey}/>
            </div>
            <div className={"display-column"}>
                <TabView className={"full-tab-view"}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    <TabPanel  header={"Attributes"}>
                        <AttributeManager selectedKey={selectedKey}></AttributeManager>
                    </TabPanel>
                    <TabPanel  header={"Ratings"}>
                        <ServiceGroups selectedKey={selectedKey} />
                     </TabPanel>
                </TabView>


            </div>
        </div>
    </div>
}

