import {useEffect, useState} from "react";
import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";
import {paramString} from "./common/urlSerializeer";

export const useJobTaskService = (params) =>{
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        get().then((res)=>{

        });
    },[]);
    const get = async ()=>{
        setLoading(true)
        let {data:{job_tasks}} = await axios.get(`${settings.url}/api/job_tasks${paramString(params)}`,config());
        setLoading(false)
        setData(job_tasks);
        return job_tasks;
    }
    const {config} = authorizationService();
    return {
        loading,
        refresh:get,
        data:data,
        get:get,
        getOne:async (id)=>{
            let {data:{job_other}} = await axios.get(`${settings.url}/api/job_tasks/${id}`,config());
            return job_other;
        },
        deleteData: async (id)=>{
            await axios.delete(`${settings.url}/api/job_tasks/${id}`,config());
            await get();
        },
        save:async (data)=>{
            data.id ?
                await axios.put(`${settings.url}/api/job_tasks/${data.id}`,{...data,...params},config()) :
                await axios.post(`${settings.url}/api/job_tasks`,{...data,...params},config());
            await get();
        }}
}