import { getData } from "./getDataHook";
import axios from "axios";
import { settings } from "./settings";
import { authorizationService } from "./authorizationService";

export const UserService = () => {
  const { get } = getData({ objPath: "users" });
  const { config } = authorizationService();
  return {
    get: async () => {
      return await get();
    },
    save: async (data) => {
      data.id
        ? await axios.put(
            `${settings.url}/api/users/${data.id}`,
            data,
            config()
          )
        : await axios.post(`${settings.url}/api/users`, data, config());
    },
    authenticate: async (req) => {
      const { data } = await axios.post(`${settings.url}/api/auth/login`, req);
      return data;
    },
    updatePassword: async (data) => {
      await axios.post(
        `${settings.url}/api/auth/update_password/${data.token}`,
        data
      );
    },
    resendWelcome: async (data) => {
      await axios.post(
        `${settings.url}/api/users/resend_welcome`,
        data,
        config()
      );
    },
  };
};
