import React, {useEffect, useState} from "react";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PButton} from "../controlls/PButton";
import {usePropertyTypesService} from "../../services/usePropertyTypeService";
import {AddPropertyType} from "../management/property_types/AddPropertyType";


export const PropertyTypeList = () =>{
    const [suppliers, setSuppliers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [show, setShow] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'name': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [loading, setLoading] = useState(true);
    const {get, deleteData} = usePropertyTypesService();

    useEffect(() => {
        get().then(data => {
            setSuppliers(data || []);
            setLoading(false)
        });
    }, []);
    const deleteRow = async (row)=>{
        if(window.confirm("Are your sure you would like to delete?")){
            await deleteData(row.id);
            const data = await get();
            setSuppliers(data || []);
        }
    }
    const updated = async ()=>{
        setLoading(true);
        const data = await get()
        setSuppliers(data || []);
        setLoading(false);
    }

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between align-items-center">
                <h5 className="m-0">Supplier Types</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }
    const detailsLinkTemplate = (rowData) =>{
        return rowData.name;
    }
    const actionBodyTemplate = (rowData) => {
        return <>
            <Button type="button" icon="pi pi-cog" onClick={()=>{
                setCurrentRow(rowData);
                setShow(true);
            }}></Button> &nbsp;
            <Button type="button" icon="pi pi-trash" className={"btn btn--primary"} onClick={async ()=>{
                await deleteRow(rowData);
            }}></Button></>;
    }

    const header = renderHeader();
    const basicParams = {
        className:"p-datatable-customers",
        paginatorTemplate:"FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
        rows:10,
        rowsPerPageOptions:[10,25,50],
        dataKey:"id",
        filterDisplay:"menu",
        responsiveLayout:"scroll",
        currentPageReportTemplate:"Showing {first} to {last} of {totalRecords} entries",
        paginator:true,
        rowHover:true
    };
    const dtParams = {
        ...basicParams,
        value:suppliers || [],
        header:header,
        selection:selectedCustomers,
        onSelectionChange:(e) => setSelectedCustomers(e.value),
        filters:filters,
        loading:loading,
        globalFilterFields:['name'],
        emptyMessage:"No suppliers found."};
    const columnExp = [
        {selectionMode:"multiple", headerStyle:{ width: '3em' }},
        {field:"name",header:"Name", sortable:true, filter:true, filterPlaceholder:"Search by name", style:{ minWidth: '14rem' }, body:detailsLinkTemplate },

    ]
    return (
        <div className="datatable-doc-demo">
            <div className={"data-action-row"}>
                <PButton click={()=>setShow(true)}  text={"Add"} />
                <AddPropertyType setShowModal={setShow} showModal={show} currentRow={currentRow} updated={updated}> </AddPropertyType>
            </div>
            <div className="card">
                <DataTable   {...dtParams}>
                    {columnExp.map((item, index)=>{
                        return <Column key={"column-val-" + index} {...item} />
                    })}
                    <Column headerStyle={{  textAlign: 'center' }} filter={false} bodyStyle={{ minWidth: '11rem',textAlign: 'center', overflow: 'visible' }} body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
    );
}
