import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { usePropertyService } from "../../services/usePropertyService";
import { GeneralPropertyForm } from "../../components/management/properties/GeneralPropertyForm";
import axios from "axios";
import { settings } from "../../services/settings";
import { authorizationService } from "../../services/authorizationService";
import { usePropertyAttachments } from "../../services/usePropertyAttachments";
import { ImageViewer } from "../../components/controlls/Images/ImageViewer";
import { BasicApiMap } from "../../components/controlls/Maps/BasicApiMap";
import { PropertyContacts } from "./PropertyContacts";
import { PropertyDetailView } from "./PropertyDetailView";
import { PropertyNotes } from "./PropertyNotes";
import { PropertyJobs } from "./PropertyJobs";
import { PropertyOpportunity } from "./PropertyOpportunity";
import {
  EditRow,
  ViewHeader,
} from "../../components/controlls/DetailTemplates/Headers";
import { TabSelector } from "../../components/controlls/TabSelector";
const tabs = [
  { name: "Details", key: "details", href: "#", current: true },
  { name: "Map", href: "#", current: false },
  { name: "Contacts", href: "#", current: false },
  { name: "Images", href: "#", current: false },
  { name: "Notes", href: "#", current: false },
  { name: "Jobs", href: "#", current: false },
  { name: "Opportunities", href: "#", current: false },
];
export const PropertyDetails = () => {
  const [data, setData] = useState({ lat: 0, lng: 0 });
  const [editMode, setEditMode] = useState(false);
  const { save, getOne } = usePropertyService({});
  const [activeIndex, setActiveIndex] = useState(0);
  let { propertyId } = useParams();
  const {
    data: propertyAttachments,
    deleteData,
    get,
  } = usePropertyAttachments({ property_id: propertyId });
  const navigate = useNavigate();
  const { config } = authorizationService();

  useEffect(() => {
    getRecord().then((res) => {});
  }, []);
  const getRecord = async () => {
    let res = await getOne(propertyId);
    setData(res);
  };
  const onSave = async () => {
    await save({ ...data });
    setEditMode(false);
    await getRecord();
  };
  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div style={{ height: "100%" }}>
      <div className="md:flex md:items-center md:justify-between py-3">
        <ViewHeader title={`Property: ${data.name}`} />
      </div>
      <TabSelector
        items={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      ></TabSelector>
      {activeIndex === 0 && (
        <>
          <PropertyDetailView data={data} setData={setData} onSave={onSave} />
        </>
      )}
      {activeIndex === 1 && (
        <>
          <BasicApiMap locationItems={[data]} />
        </>
      )}
      {activeIndex === 2 && (
        <>
          <PropertyContacts propertyId={propertyId} />
        </>
      )}
      {activeIndex === 3 && (
        <>
          <ImageViewer
            attachments={propertyAttachments}
            onDelete={deleteData}
            onSave={async (image) => {
              await axios.post(
                `${settings.url}/api/properties/upload?property_id=${propertyId}`,
                image,
                config()
              );
              await get();
            }}
          ></ImageViewer>
        </>
      )}
      {activeIndex === 4 && (
        <>
          {" "}
          <PropertyNotes propertyId={propertyId} />
        </>
      )}
      {activeIndex === 5 && (
        <>
          {" "}
          <PropertyJobs propertyId={propertyId} />
        </>
      )}
      {activeIndex === 6 && (
        <>
          {" "}
          <PropertyOpportunity propertyId={propertyId}></PropertyOpportunity>
        </>
      )}
    </div>
  );
};
