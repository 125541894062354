import React, {useCallback, useEffect, useState} from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
export const BasicApiMap = ({locationItems=[]}) =>{
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyD-qq-lA7Cy4GXVOfa27QnxtMpVr2XoQeE"
    })
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });
    const [locationData, setLocationData] = useState([]);
    const [map, setMap] = useState(null)
    useEffect(()=>{
        if(map){
            let locData = locationItems.filter(i=>i.latitude && i.longitude).map((item)=>{
                return {lat:+item.latitude, lng:+item.longitude};
            });
            refreshMap(map, locData);
            setLocationData([...locData]);
        }
        return ()=>{
            setLocationData([]);

        }
    },[map, locationItems]);

    const refreshMap = (mapItem, mapData=[]) =>{
        if(mapItem && mapData.length === 0) return;
        const bounds = new window.google.maps.LatLngBounds();
        mapData.forEach((item)=>{
            bounds.extend(item);
        });
        mapItem.fitBounds(bounds);
        setTimeout(()=>{
            console.log("length",mapData.length);
            if(mapData.length === 1)
                mapItem.setZoom(14);
        },100)
    }
    const onLoad = useCallback((map) => setMap(map), []);
    //const onUnmount = useCallback((map) => setMap(null), []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={{ flexGrow: "1", height: "600px" }}
            center={center}
            onLoad={onLoad}

        >
            {locationData.filter(i=> i.lat && i.lng).map((locate, index)=> {
                return <Marker key={`map-marker-${index}`} position={locate}/>
            }) }
        </GoogleMap>
    ) : <></>
}