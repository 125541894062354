import {useProficiencyCategoryService} from "../../../services/proficiencies/useProficiencyCategoryService";
import React from "react";
import {ProficiencyCategory} from "./ProficiencyCategory";

export const  ProficiencyCategories = ({group}) =>{
    const {save:saveCategory, data:categories,deleteData} = useProficiencyCategoryService({params:{proficiency_group_id:group.id}});
    return <>
        <h3>Service Categories <ProficiencyCategory save={saveCategory}  proficiency_group_id={group.id}></ProficiencyCategory></h3>

        <table className={"table"}>
            <thead>
            <tr>
                <th>Category</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {categories.map((category, index)=>{
                return <tr key={"category-" + category.id}>
                    <td>{category.name}</td>
                    <td><button className={"link-button"} onClick={async ()=>{await deleteData(category.id) }}><i className={"fa-solid fa-trash"}></i></button></td>
                </tr>
            })}
            </tbody>
        </table>
    </>;
}
