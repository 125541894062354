import React,{useState, useEffect} from 'react';

export const ImportColumns = ({cols}) =>{
    const [curCols, setCurCols] = useState(cols || []);
    useEffect(()=>{
        if(cols)
        {setCurCols(cols);}
    },[cols]);
    return  <div className={'container-fluid'} style={{height:'500px'}}>
        {curCols.map((col, index)=>{
            return <div key={'column_num_' + index}>{col}</div>;
        })}
    </div>;
};