import { MaterialTypesList } from "../components/Lists/MaterialTypesList";
import { JobList } from "../components/Lists/JobList";

export const Jobs = () => {
  return (
    <>
      <JobList />
    </>
  );
};
