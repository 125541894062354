import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import * as moment from "moment";
export const useListHelper = ({
  pagination,
  title,
  globalFields,
  filters: reqFilters,
  onChange,
  onDelete,
}) => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [show, setShow] = useState(false);
  const [lazyParams, setLazyParams] = useState({
    onPage: async (event) => {
      let { page, rows, totalRecords, sortField, sortOrder, filters } = event;
      setLazyParams({
        ...lazyParams,
        rows,
        totalRecords,
        sortField,
        sortOrder,
        filters,
      });
      if (onChange)
        await onChange({
          ...{
            page: page + 1,
            rows,
            totalRecords,
            sortField,
            sortOrder,
            filters,
          },
        });
    },
    onSort: async (event) => {
      let { page, rows, totalRecords, sortField, sortOrder, filters } = event;
      setLazyParams({ ...lazyParams, sortField, sortOrder, filters });
      if (onChange)
        await onChange({
          ...{
            page: page + 1,
            rows,
            totalRecords,
            sortField,
            sortOrder,
            filters,
          },
        });
    },
    onFilter: async (event) => {
      event["first"] = 0;
      let { rows, totalRecords, sortField, sortOrder, filters } = event;
      setLazyParams({
        ...lazyParams,
        rows,
        totalRecords,
        sortField,
        sortOrder,
        filters,
      });
      if (onChange)
        await onChange({
          ...{ page: 1, rows, totalRecords, sortField, sortOrder, filters },
        });
    },
    first: 0,
    page: 0,
    rows: 10,
    totalRecords: 0,
    sortField: null,
    sortOrder: null,
    filters: reqFilters,
  });
  useEffect(() => {
    let { rowCount = 0, page, pageSize } = pagination;
    setLazyParams({
      ...lazyParams,
      totalRecords: rowCount,
      first: (page - 1) * pageSize,
    });
  }, [pagination]);

  const onGlobalFilterChange = async ({ target: { value } }) => {
    let paramVals = { ...lazyParams };
    paramVals.filters["global"]["value"] = value;
    setLazyParams(paramVals);
    setGlobalFilterValue(value);
    if (onChange)
      await onChange({ ...paramVals, page: 1, globalFields: globalFields });
  };
  const onDeleteRow = async (row) => {
    if (window.confirm("Are your sure you would like to delete?")) {
      await onDelete(row);
    }
  };

  return {
    lazyParams,
    show,
    setShow,
    currentRow,
    onGlobalFilterChange,
    formatDate: (value) => {
      return moment(value).format("MM/DD/YYYY");
    },
    formatCurrency: (value) => {
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    actionBodyTemplate: (rowData) => {
      return (
        <>
          <Button
            type="button"
            icon="pi pi-cog"
            onClick={() => {
              setCurrentRow(rowData);
              setShow(true);
            }}
          ></Button>{" "}
          &nbsp;
          <Button
            type="button"
            icon="pi pi-trash"
            className={"btn btn--primary"}
            onClick={async () => {
              await onDeleteRow(rowData);
            }}
          ></Button>
        </>
      );
    },
    renderHeader: () => {
      return (
        <div className="flex justify-content-between align-items-center">
          <h5 className="m-0">{title}</h5>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      );
    },
  };
};
