import {Link} from "react-router-dom";

export const Header = ({toggleMobileSidenav}) =>{
    return <div className={"header"}>
        <div className={"toggle-menu-m-d"}>
            <button key={"toggle-button-1"} className={"menu-button"} onClick={toggleMobileSidenav}><i className={"fa-solid fa-bars"}></i></button>
        </div>
        <div>
            Job Flow
        </div>

        <div>
            <Link to={"/users/login"}>Register</Link> &nbsp;
            {localStorage.getItem("user") &&
                <Link to={"/users/login"} onClick={()=>{
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                }}>Log Out</Link>
            }
            {!localStorage.getItem("user") && <Link to={"/users/login"} >Log In</Link>}
        </div>
        </div>
}