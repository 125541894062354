import {INITIAL, INVALID, VALID} from "../../services/validations";
import {useEffect, useState} from "react";
import {useIsValid} from "../../services/useIsValid";

export const PasswordField = ({valid=INITIAL, validations, name, placeholder, onChange,value='', obj}) =>{
    const [isValid, setIsValid] = useState(INITIAL);
    const [invalidMessage, setInvalidMessage] = useState([]);
    const isValidHook = useIsValid(validations);

    useEffect(()=> {
        if (valid !== INITIAL) {
            assignValidation(value)
        }
    },[valid]);

    const changeHandler = (e) => {
        assignValidation(e.target.value);
        onChange(e);
    }
    const assignValidation = (targetValue) => {
        const invalidArray = isValidHook.isValidFunc(targetValue,obj);
        if (invalidArray.length > 0) {
            setIsValid(INVALID);
            setInvalidMessage(invalidArray);
        } else {
            setIsValid(VALID);
            setInvalidMessage([]);
        }
    }
    return <><input  type="password"
                     placeholder={placeholder}
                     name={name}
                     autoComplete={'off'}
                     className={`form--input ${(isValid !==  INITIAL ? (isValid !== INVALID ? 'form--input--valid' : 'form--input--invalid') :'')}`}
                     onChange={changeHandler}
                     value={value}/>
        <div className={"form--invalid-feedback"}>
            {invalidMessage.map((message, index)=>{
                return <div key={`validation-${name}-${index}`}>{message}</div>
            })}
        </div>
    </>
}