import {useEffect, useState} from "react";
import {useVendorCategoryService} from "../../services/useVendorCategoryService";
import {Tree} from "primereact/tree";

export const VendorCategoriesEdit = ({vendor,selected,save,deleteList}) =>{
    const [nodes, setNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const {data:categories} = useVendorCategoryService({params:{}});

    const getChildren = (parent)=>{
        let children = categories.filter(i=>i.parent_vendor_category_id === parent.id);
        children.forEach((child)=>{
            child.children = getChildren(child);
        });
        return children.map((child)=>{
            return {
                "key": child.id,
                "label": child.name,
                "data": child.name,
                leaf: !!child.children,
                children: child.children
            }
        });
    }
    useEffect(() => {
        let keys = {};
        selected.forEach(i => {
            keys[i.vendor_category_id] = {checked:true};
        });
        let parents = categories.filter(i=>i.parent_vendor_category_id === 0);
        let nodes = parents.map((category, index)=>{
            return {
                "id":category.id,
                "key": category.id,
                "label": category.name,
                "data": category.name,
                leaf: false.valueOf(),
                children: getChildren(category)
            }
        });
        setSelectedKeys(keys);
        setNodes(nodes || []);
    }, [categories,selected]);

    const saveData = async () =>{
        await deleteList(vendor.id);
        let categories = Object.keys(selectedKeys).map((item)=>{
            return {vendor_id: vendor.id, vendor_category_id:item}
        })
        await save(categories);
    }
    return <>
        <button className={"btn btn--primary"}
                onClick={async ()=>{
                    await saveData();
                }}>Save</button>
        <div>
            <br/>
            <Tree value={nodes} selectionMode="checkbox"
                  selectionKeys={selectedKeys}
                  propagateSelectionDown={false}
                  onSelectionChange={e => {
                      console.log(e.value);
                      setSelectedKeys(e.value)
                  }} />
        </div>
    </>
}

