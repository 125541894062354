import {useBasicServices} from "./useBasicServices";
import axios from "axios";
import {settings} from "./settings";
import {authorizationService} from "./authorizationService";

export const useVendorVendorCategoryService = ({params={}}) =>{
    let serviceBase = useBasicServices({service_type:'vendor_vendor_categories', params:params});
    const {config} = authorizationService();
    serviceBase.deleteByVendor = async (id) =>{
        await axios.get(`${settings.url}/api/vendor_vendor_categories/delete_by_vendor/${id}`,config());

    }
    return serviceBase;
}