import React, { useState, useEffect } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";

import * as moment from "moment";
import {
  BriefcaseIcon,
  CalendarIcon,
  ChevronRightIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/20/solid";
import { AddJob } from "../management/job/AddJob";
import { UsersAdd } from "../../pages/admin/UsersAdd";
import { UserService } from "../../services/UserService";
import * as PropTypes from "prop-types";

function SubMenu({ onEdit, onDelete }) {
  const [subShow, setSubShow] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setSubShow(!subShow);
        }}
      >
        <ChevronRightIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
      </button>
      {subShow && (
        <div className={"bg-white absolute w-56 h-40 border rounded -right-1"}>
          <button
            className={
              "group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600"
            }
            onClick={() => {
              setSubShow(false);
              onEdit();
            }}
          >
            Edit
          </button>
          <button
            className={
              "group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-600"
            }
            onClick={() => {
              setSubShow(false);
              onDelete();
            }}
          >
            Delete
          </button>
        </div>
      )}
    </>
  );
}

SubMenu.propTypes = {
  data: PropTypes.any,
  setShow: PropTypes.func,
};
export const UsersList = ({}) => {
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
  });
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const { save, get, resendWelcome } = UserService();
  useEffect(() => {
    get().then((data) => {
      setUsers(data);
      setLoading(false);
    });
  }, []);
  const update = ({ target: { value, name } }) => {
    setData({ ...data, ...{ [name]: value } });
  };
  const saveUser = async () => {
    await save(data);
    let newUsers = await get();
    setUsers(newUsers);
    setData({ email: "", first_name: "", last_name: "" });
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const formatDate = (value) => {
    return moment(value).format("MM/DD/yyy");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  return (
    <>
      <div className="">
        <div className="border-b border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Users
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                onClick={() => setShow(true)}
                type="button"
                className="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create new user
              </button>
            </div>
          </div>
        </div>

        <div className="py-4 bg-white  flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative flex flex-1" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <MagnifyingGlassIcon
              className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
              aria-hidden="true"
            />
            <input
              id="search-field"
              className="block h-full w-full border-0 py-0 pl-5 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
              type="search"
              name="search"
              onChange={onGlobalFilterChange}
            />
          </form>
        </div>

        <div className="overflow-hidden bg-white shadow sm:rounded-md">
          <ul role="list" className="divide-y divide-gray-200">
            {users.map((user) => (
              <li key={user.id}>
                <div className="block hover:bg-gray-50">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="flex min-w-0 flex-1 items-center">
                      <div className="flex-shrink-0">
                        <UserIcon
                          className="mr-1.5 h-10 w-10 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {user.first_name} {user.last_name}
                          </p>
                          <p className="block md:hidden mt-2 flex items-center text-sm text-gray-500">
                            <GlobeAltIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="truncate">{user.email}</span>
                          </p>
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <p className="mt-2 mr-4 flex items-center text-sm text-gray-500">
                          <GlobeAltIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="truncate">{user.email}</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <SubMenu
                        onEdit={() => {
                          setData({ ...user });
                          setShow(true);
                        }}
                        onDelete={() => {}}
                      />
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <UsersAdd
        saveUser={saveUser}
        resendWelcome={resendWelcome}
        data={data}
        setShow={setShow}
        showModal={show}
        update={update}
      />
    </>
  );
};
