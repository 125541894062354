import {Button, ButtonGroup, Form, FormGroup, Input, Modal} from "reactstrap";
import classnames from "classnames";
import React, {useState} from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";

export  const EventModal = ({save,deleteItem,modalChange, setModalChange, setAlert}) =>{
    const [radios, setRadios] = useState(null);
    const [eventTitle, setEventTitle] = useState(null);
    const [eventDescription, setEventDescription] = useState(null);

    const [eventId, setEventId] = useState(null);
    const [event, setEvent] = useState(null);


    const changeEvent = async () => {


        //const {data} = await editTask(eventId, eventTitle, eventDescription, radios, startDate, endDate);
        //ToDo: Edit Tasks
        const data = [];

        setModalChange(false);
        setRadios("bg-info");
        setEventTitle(undefined);
        setEventDescription(undefined);
        setEventId(undefined);
        setEvent(undefined);
    };
    const deleteEventSweetAlert = () => {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => {
                    setAlert(false);
                    setRadios("bg-info");
                    setEventTitle(undefined);
                    setEventDescription(undefined);
                    setEventId(undefined);
                }}
                onCancel={() => deleteEvent()}
                confirmBtnCssClass="btn-secondary"
                cancelBtnBsStyle="danger"
                confirmBtnText="Cancel"
                cancelBtnText="Yes, delete it"
                showCancel
                btnSize=""
            >
                You won't be able to revert this!
            </ReactBSAlert>
        );
    };
    const deleteEvent = async () => {
        //const {data} = await deleteTask(eventId);
        //ToDO: Delete tasks
        const data = []
        setEvent(undefined);
        setAlert(
            <ReactBSAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success"
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle="primary"
                confirmBtnText="Ok"
                btnSize=""
            >
                A few words about this sweet alert ...
            </ReactBSAlert>
        );
        setModalChange(false);

        setRadios("bg-info");
        setEventTitle(undefined);
        setEventDescription(undefined);
        setEventId(undefined);
        setEvent(undefined);
    };

    return  <Modal
        isOpen={modalChange}
        toggle={() => setModalChange(false)}
        className="modal-dialog-centered modal-secondary"
    >
        <div className="modal-body">
            <Form className="edit-event--form">
                <FormGroup>
                    <label className="form-control-label">Event title</label>
                    <Input
                        className="form-control-alternative edit-event--title"
                        placeholder="Event Title"
                        type="text"
                        defaultValue={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <label className="form-control-label d-block mb-3">
                        Status color
                    </label>
                    <ButtonGroup
                        className="btn-group-toggle btn-group-colors event-tag mb-0"
                        data-toggle="buttons"
                    >
                        <Button
                            className={classnames("bg-info", {
                                active: radios === "bg-info",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-info")}
                        />
                        <Button
                            className={classnames("bg-warning", {
                                active: radios === "bg-warning",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-warning")}
                        />
                        <Button
                            className={classnames("bg-danger", {
                                active: radios === "bg-danger",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-danger")}
                        />
                        <Button
                            className={classnames("bg-success", {
                                active: radios === "bg-success",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-success")}
                        />
                        <Button
                            className={classnames("bg-default", {
                                active: radios === "bg-default",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-default")}
                        />
                        <Button
                            className={classnames("bg-primary", {
                                active: radios === "bg-primary",
                            })}
                            color=""
                            type="button"
                            onClick={() => setRadios("bg-primary")}
                        />
                    </ButtonGroup>
                </FormGroup>
                <FormGroup>
                    <label className="form-control-label">Description</label>
                    <Input
                        className="form-control-alternative edit-event--description textarea-autosize"
                        placeholder="Event Desctiption"
                        type="textarea"
                        defaultValue={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                    />
                    <i className="form-group--bar" />
                </FormGroup>
                <input className="edit-event--id" type="hidden" />
            </Form>
        </div>
        <div className="modal-footer">
            <Button color="primary" onClick={changeEvent}>
                Update
            </Button>
            <Button
                color="danger"
                onClick={() => {
                    setModalChange(false);
                    deleteEventSweetAlert();
                }}
            >
                Delete
            </Button>
            <Button
                className="ml-auto"
                color="link"
                onClick={() => setModalChange(false)}
            >
                Close
            </Button>
        </div>
    </Modal>
}