export const PButton = ({ click, text }) => {
  return (
    <button
      className={
        "mr-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      }
      onClick={click}
    >
      {text}
    </button>
  );
};
