import {ImportManager} from "./Import/ImportManager";
import {QueryBuilder} from "./Import/QueryBuilder";
import {useState} from "react";
import {TabPanel, TabView} from "primereact/tabview";

export const DataManagement = () =>{
    const [activeIndex, setActiveIndex] = useState(0);
    return <>
        <TabView className={"full-tab-view"}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header="Query">
                <QueryBuilder></QueryBuilder>
            </TabPanel>
            <TabPanel header="Imports">
                <ImportManager></ImportManager>
            </TabPanel>
        </TabView>
    </>
}