import React, { useEffect, useState } from "react";
import { useAccountService } from "../../services/useAccountService";
import { useNavigate, useParams } from "react-router-dom";
import { authorizationService } from "../../services/authorizationService";
import { TabPanel, TabView } from "primereact/tabview";
import { GeneralAccountForm } from "../../components/management/account/GeneralAccountForm";
import { PButton } from "../../components/controlls/PButton";
import { AccountDetailView } from "../accounts/AccountView";
import { BasicApiMap } from "../../components/controlls/Maps/BasicApiMap";
import { useVendorService } from "../../services/useVendorService";
import { GeneralVendorForm } from "../../components/management/vendors/GeneralVendorForm";
import { VendorDetailView } from "./VendorDetailView";
import { VendorLocations } from "./VendorLocations";
import { ProficiencyGrid } from "./ProficiencyGrid";
import { VendorCategoriesDisplay } from "./VendorCategoriesDisplay";
import { VendorContacts } from "./VendorContacts";
import { VendorNotes } from "./VendorNotes";
import { VendorJobs } from "./VendorJobs";
import { MessageInterface } from "../messaging/MessageInterface";
import { VendorAttributeDisplay } from "./VendorAttributes";
import { ViewHeader } from "../../components/controlls/DetailTemplates/Headers";
import { TabSelector } from "../../components/controlls/TabSelector";
const tabs = [
  { name: "Details", key: "details", href: "#", current: true },
  { name: "Map", href: "#", current: false },
  { name: "Locations", href: "#", current: false },
  { name: "Contacts", href: "#", current: false },
  { name: "Notes", href: "#", current: false },
  { name: "Jobs", href: "#", current: false },
];
export const VendorDetails = () => {
  let { vendorId } = useParams();
  const [data, setData] = useState({ id: vendorId || 0, lat: 0, lng: 0 });
  const [mapData, setMapData] = useState({ id: vendorId || 0, lat: 0, lng: 0 });
  const [editMode, setEditMode] = useState(false);
  const { save, getOne } = useVendorService({});
  const [activeIndex, setActiveIndex] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getOne(vendorId).then((res) => {
      setData(res);
      setMapData(res);
    });
    setActiveIndex(0);
  }, [vendorId]);

  const onSave = async () => {
    await save({ ...data });
    setEditMode(false);
  };
  const onAttrSave = async (lData) => {
    await save({ ...lData });
    setData({ ...lData });
    setEditMode(false);
  };

  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  const onAttrChange = (attr, value) => {
    setData({ ...data, [attr]: value });
  };
  return (
    <div style={{ height: "100%" }}>
      <div className="md:flex md:items-center md:justify-between py-3">
        <ViewHeader title={`Vendor: ${data.name}`} />
      </div>
      <TabSelector
        items={tabs}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      ></TabSelector>

      {activeIndex === 0 && (
        <>
          <VendorDetailView data={data} />
        </>
      )}
      {activeIndex === 1 && (
        <>
          <div className={" panel"}>
            <BasicApiMap locationItems={[mapData]} />
          </div>
        </>
      )}
      {activeIndex === 2 && (
        <>
          <VendorLocations parentId={vendorId} />
        </>
      )}
      {activeIndex === 3 && (
        <>
          <VendorContacts vendorId={vendorId} />
        </>
      )}
      {activeIndex === 4 && (
        <>
          <MessageInterface context={`/vendor/${data.id}`}></MessageInterface>
        </>
      )}
      {activeIndex === 5 && (
        <>
          <VendorJobs vendorId={vendorId}> </VendorJobs>
        </>
      )}
      {activeIndex === 99 && (
        <>
          <ProficiencyGrid vendorId={vendorId} />
          <VendorAttributeDisplay
            data={data}
            onChange={onAttrChange}
            save={onAttrSave}
          ></VendorAttributeDisplay>
          <VendorCategoriesDisplay
            vendor={data || { id: vendorId }}
          ></VendorCategoriesDisplay>
          <VendorNotes vendorId={vendorId}></VendorNotes>
        </>
      )}
    </div>
  );
};
