import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GeneralPropertyForm } from "../../components/management/properties/GeneralPropertyForm";
import { useDataAttributeService } from "../../services/useDataAttributeService";
import { EditRow } from "../../components/controlls/DetailTemplates/Headers";

export const PropertyDetailView = ({ data, setData, onSave }) => {
  const [editMode, setEditMode] = useState(false);

  const { data: attributes } = useDataAttributeService({
    params: { attribute_type: "customer" },
  });

  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="md:flex md:items-center md:justify-between py-3">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Detailed Property Information
            </h3>

            <EditRow
              showCancel={true}
              editMode={editMode}
              onEdit={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={async () => {
                await onSave();
                setEditMode(false);
              }}
            />
          </div>
        </div>
        {editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <GeneralPropertyForm
                data={data}
                setData={setData}
                onChange={onChange}
              />
            </div>
          </>
        )}
        {!editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Account Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    <Link to={`/accounts/${data.account_id}`}>
                      {data.account?.name}
                    </Link>
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["name"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Property Type
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["property_type"]?.name}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["address1"]}
                    <br />
                    {data["address2"]}
                    <br />
                    {data["city"]}, {data["state"]} {data["zip"]}
                    <br />
                  </dd>
                </div>
                <hr />
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Constraints
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["constraints"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Property Access
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["property_access"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Open 24 Hours
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["open_24_hrs"]}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Total Sq Ft
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["total_sq_ft"]}
                  </dd>
                </div>
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
