import React, { useState } from "react";
import { AddressView } from "../../components/controlls/Address/AddressView";
import { GeneralVendorForm } from "../../components/management/vendors/GeneralVendorForm";
import { EditRow } from "../../components/controlls/DetailTemplates/Headers";
import { useDataAttributeService } from "../../services/useDataAttributeService";
import { Link } from "react-router-dom";

export const VendorDetailView = ({ data, setData, onSave }) => {
  const [editMode, setEditMode] = useState(false);

  const { data: attributes } = useDataAttributeService({
    params: { attribute_type: "customer" },
  });

  const onChange = ({ target: { name, value } }) => {
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 sm:px-6">
          <div className="md:flex md:items-center md:justify-between py-3">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Detailed Property Information
            </h3>
            <EditRow
              showCancel={true}
              editMode={editMode}
              onEdit={() => setEditMode(true)}
              onCancel={() => setEditMode(false)}
              onSave={async () => {
                await onSave();
                setEditMode(false);
              }}
            />
          </div>
        </div>
        {editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              <GeneralVendorForm
                data={data}
                setData={setData}
                onChange={onChange}
              />
            </div>
          </>
        )}
        {!editMode && (
          <>
            <div className="border-t border-gray-200 px-4 py-3 sm:p-0">
              {data.parent_vendor && (
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Parent Location
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data.parent_vendor?.name}
                    </dd>
                  </div>
                </dl>
              )}

              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["name"]}
                  </dd>
                </div>
              </dl>

              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Website</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["website"]}
                  </dd>
                </div>
              </dl>

              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["email"]}
                  </dd>
                </div>
              </dl>

              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["primary_phone"]}
                  </dd>
                </div>
              </dl>
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">Address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data["address1"]}
                    <br />
                    {data["city"]}, {data["state"]} {data["zip"]}
                    <br />
                  </dd>
                </div>
              </dl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
