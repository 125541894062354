import React, {useEffect, useState} from "react";
import {useSupplierTypesService} from "../../../services/useSupplierTypesService";
import {Dialog} from "primereact/dialog";
import {PButton} from "../../controlls/PButton";
import * as PropTypes from "prop-types";
import {usePropertyTypesService} from "../../../services/usePropertyTypeService";

export const  AddPropertyType = ({setShowModal, showModal=false,currentRow, updated}) =>{
    const [show, setShow] = useState(false);
    const [data, setData] = useState(currentRow);
    const {save} = usePropertyTypesService();
    useEffect(()=>{
        setShow(showModal)
    },[showModal])
    useEffect(()=>{
        setData(currentRow);
    },[currentRow])
    const onSave = async () =>{
        await save({...data});
        setData({});
        setShowModal(false);
        await updated();
    };
    const onChange = ({target:{name, value}})=>{
        setData( {...data,[name]:value});
    }
    return <div>

        <Dialog header="Add Property Type" style={{ width: '50vw' }}  visible={show} onHide={() => setShowModal(false)}>
            <div className="form--group">
                <label htmlFor="">Name</label>
                <input name={"name"} onChange={onChange} value={data["name"]} type="text" className="form--input"/>
            </div>
            <div>
                <PButton text={"save"} click={async ()=>{
                    await onSave();
                }}/>
            </div>
        </Dialog>
    </div>
}

AddPropertyType.propTypes = {
    setShowModal: PropTypes.func,
    currentRow: PropTypes.shape({}),
    showModal: PropTypes.bool,
    updated: PropTypes.func
};