import { useVendorService } from "../../services/useVendorService";
import React, { useEffect, useState } from "react";

export const VendorAttributeDisplay = ({ data, onChange, save }) => {
  const { getAttributes } = useVendorService({ id: data.id });
  const [attrs, setAttrs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const onSave = async (data) => {
    await save(data);
    setEditMode(false);
  };
  useEffect(() => {
    getAttributes().then((data) => {
      setAttrs(data);
    });
  }, [data]);
  return (
    <>
      <div>
        <h1>Profile</h1>
        {!editMode && (
          <div>
            {attrs.map((attr, index) => {
              return (
                <div className={"form--group"} key={`attr-form--${index}`}>
                  <label htmlFor="">{attr.attribute_display}</label>
                  <div className={"data-view-display"}>
                    {(data.attribute_data || {})[attr.name]}&nbsp;
                  </div>
                </div>
              );
            })}
            <div>
              <button
                className={"btn btn--primary"}
                onClick={() => {
                  setEditMode(true);
                }}
              >
                Edit
              </button>
            </div>
          </div>
        )}
        {editMode && (
          <VendorAttributeForm
            save={onSave}
            data={data}
            form={attrs}
            onChange={onChange}
          ></VendorAttributeForm>
        )}
      </div>
    </>
  );
};

export const VendorAttributeForm = ({ data, form, onChange, save }) => {
  const [lData, setLData] = useState({});
  useEffect(() => {
    setLData({ ...data });
  }, [data]);
  const lOnChange = (field, value) => {
    setLData({
      ...lData,
      attribute_data: { ...lData.attribute_data, [field.name]: value },
    });
  };
  const lSave = async () => {
    await save(lData);
  };
  return (
    <>
      <div>
        <div className={"form--group"}>
          {form.map((attr, index) => {
            return (
              <div key={`attr-edit-form--${index}`}>
                <label htmlFor="">{attr.attribute_display}</label>
                <div className={"data-view-display"}>
                  <input
                    type="text"
                    className={"form--input"}
                    value={(lData.attribute_data || {})[attr.name] || ""}
                    onChange={({ target: { value } }) => {
                      lOnChange(attr, value);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <button className={"btn btn--primary"} onClick={lSave}>
          Save
        </button>
      </div>
    </>
  );
};
